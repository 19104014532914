import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../commons/Application/Application.context';
import { ChoiceItem } from '../ChoiceItem/ChoiceItem';
import classes from './Choices.module.sass';

export const Choices = ({ items, onChange, value, multichoice, classes: receivedClasses = {} }) => {
    const { isSmall } = React.useContext(ApplicationContext);

    const handleClick = React.useCallback(
        (selectedValue) => () => {
            if (typeof onChange !== 'function') {
                return;
            }
            if (multichoice) {
                const newValue = [...(value || [])];

                if (newValue.includes(selectedValue)) {
                    newValue.splice(
                        newValue.findIndex((key) => key === selectedValue),
                        1
                    );
                } else {
                    newValue.push(selectedValue);
                }
                onChange(newValue);
                return;
            }

            onChange(selectedValue);
        },
        [onChange, multichoice, value]
    );

    return (
        <div className={cn(classes.root, isSmall && classes.small, receivedClasses.root)}>
            {items.map((item) => (
                <ChoiceItem
                    key={`choice_item_${item.value}`}
                    classes={{ root: classes.item }}
                    onClick={handleClick(item.value)}
                    isSelected={Array.isArray(value) ? value.includes(item.value) : item.value === value}
                    {...item}
                />
            ))}
        </div>
    );
};
