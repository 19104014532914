import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import classes from './ProgressBar.module.sass';

export const ProgressBar = ({ value = 0, classes: receivedClasses = {} }) => {
    const progressStyle = useSpring({
        translate: value,
    });

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            <animated.div
                className={classes.progress}
                style={{
                    transform: progressStyle.translate.to((translateX) => `translate3d(${-100 + translateX}%, 0, 0)`),
                }}
            />
        </div>
    );
};
