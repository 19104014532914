import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { FormField } from '../../../../commons/FormField/FormField';
import { TextField } from '../../../../designSystem/TextField/TextField';
import { Typography } from '../../../../designSystem/Typography/Typography';
import { StepsUtilsContext } from '../../Steps.context';
import { STEPS } from '../../Steps.data';
import { routesTitles } from '../../Steps.titles';
import classes from './EstimatedBudgetFields.module.sass';

export const EstimatedBudgetFields = () => {
    const { language } = React.useContext(ApplicationContext);
    const { values } = React.useContext(StepsUtilsContext);
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const selectedHousingType = values[routesTitles.CALCULATE_AIDS] && values[routesTitles.CALCULATE_AIDS].value;
    const worksStepId = selectedHousingType === 'house' ? '4.8.1' : '4.8.2';
    const selectedWork = values[worksStepId] && values[worksStepId].value;

    const translationKey = React.useMemo(() => {
        const category = Object.values(STEPS[worksStepId].categories).find(({ choices }) =>
            Object.keys(choices).includes(selectedWork)
        );
        if (!category) {
            return null;
        }
        const choice = category.choices[selectedWork];
        return choice.translationKey;
    }, [worksStepId, selectedWork]);

    if (!selectedWork) {
        return null;
    }

    return (
        <FormField label={language.steps[translationKey]} error={errors.value}>
            <TextField
                classes={{
                    root: classes.textField,
                }}
                afterInput={<Typography>{'€ TTC'}</Typography>}
                {...register('value')}
            />
        </FormField>
    );
};
