export const FR = {
    isolation: 'Isolation à partir de 1 € : Testez votre éligibilité !*',
    renovationEligibility: 'Un projet de rénovation énergétique ? Testez votre éligibilité pour nos offres.',
    heatHomeWater: 'Votre nouveau Chauffe Eau : Testez votre éligibilité pour nos offres',
    heatHome: 'Votre nouveau chauffage : Testez votre éligibilité pour nos offres',
    isolateHome: "L'isolation de votre maison: Testez votre éligibilité pour nos offres",
    refreshHome: "Besoin d'un coup de frais? Testez votre éligibilité pour nos offres",
    renovateHome: 'Rénovation Globale à partir de 1€ : testez votre éligibilité !*',
    ventilateHome: "Un projet d'installation de ventilation ? Testez votre éligibilité pour nos offres",
    shortHome: 'Un projet de rénovation énergétique ? Contactez-nous !',
};

export const EN = {
    isolation: 'Isolation from 1€, test your eligibility!*',
    renovationEligibility: 'Un projet de rénovation énergétique ? Testez votre éligibilité pour nos offres.',
    heatHomeWater: 'Votre nouveau Chauffe Eau : Testez votre éligibilité pour nos offres',
    heatHome: 'Votre nouveau chauffage : Testez votre éligibilité pour nos offres',
    isolateHome: "L'isolation de votre maison: Testez votre éligibilité pour nos offres",
    refreshHome: "Besoin d'un coup de frais? Testez votre éligibilité pour nos offres",
    renovateHome: 'Rénovation Globale à partir de 1€ : testez votre éligibilité !*',
    ventilateHome: "Un projet d'installation de ventilation ? Testez votre éligibilité pour nos offres",
    shortHome: 'Un projet de rénovation énergétique ? Contactez-nous !',
};

export const titlesLanguage = {
    fr: FR,
    us: EN,
};
