import cn from 'classnames';
import * as React from 'react';

import { Typography } from '../../designSystem/Typography/Typography';
import { ApplicationContext } from '../Application/Application.context';
import classes from './FormField.module.sass';

export const FormField = ({ label, error, children, classes: receivedClasses = {} }) => {
    const { isSmall } = React.useContext(ApplicationContext);

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            {Boolean(label) && (
                <Typography component="div" className={cn(classes.label, isSmall && classes.small)}>
                    {label}
                </Typography>
            )}
            {children}
            {Boolean(error) && (
                <Typography classes={{ root: classes.error }} component="div">
                    {typeof error === 'string' ? error : error.message}
                </Typography>
            )}
        </div>
    );
};
