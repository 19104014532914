import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Button } from '../../../../designSystem/Button/Button';
import { StepsUtilsContext } from '../../Steps.context';
import classes from './Form.module.sass';
import { getChoicesSchema } from './Form.utils';
import { FormChoices } from './FormChoices/FormChoices';

export const Form = ({ step, stepId }) => {
    const { goToNextStep, currentStepId, values: stepsValues, buttonText } = React.useContext(StepsUtilsContext);
    const { language, isSmall } = React.useContext(ApplicationContext);

    /*
    Schema depends on current step's type.
    if type is 'fields', then the schema is already made & present in step.
    if type is 'choices', then we build a custom schema which only accepts one of the provided choices' ids.
    */
    const schema = React.useMemo(() => {
        const { type } = step;
        if (type === 'choices') {
            const { multichoice } = step;
            let choicesIds;
            if (step.hasCategories) {
                choicesIds = Object.values(step.categories).reduce(
                    (acc, { choices }) => [...acc, ...Object.keys(choices)],
                    []
                );
            } else {
                choicesIds = Object.keys(step.choices);
            }
            return getChoicesSchema(choicesIds, multichoice, language.form);
        }
        return step.getValidationSchema(language.form);
    }, [step, language.form]);

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: stepsValues[stepId],
        reValidateMode: 'onSubmit',
    });

    const values = methods.watch();
    const { type } = step;
    const isButtonDisabled = React.useMemo(() => {
        if (typeof step.getShouldButtonBeDisabled === 'function') {
            return step.getShouldButtonBeDisabled(values);
        }
        if (type === 'choices') {
            return !values.value || (Array.isArray(values.value) && values.value.length === 0);
        }
        return true;
    }, [values, step, type]);

    const onSubmit = methods.handleSubmit((data) => {
        // Prevents multiple submits, making the app crash.
        if (currentStepId !== stepId) {
            return;
        }
        goToNextStep(data);
    });

    return (
        <FormProvider {...methods}>
            <form className={cn(classes.form, isSmall && classes.small)} {...{ onSubmit }}>
                <FormContent {...{ step }} />
                <Button classes={{ root: classes.submitButton }} type="submit" disabled={isButtonDisabled}>
                    {buttonText}
                </Button>
            </form>
        </FormProvider>
    );
};

const FormContent = ({ step }) => {
    const { type } = step;

    if (type === 'choices') {
        return (
            <FormChoices
                hasCategories={step.hasCategories}
                categories={step.categories}
                choices={step.choices}
                multichoice={step.multichoice}
            />
        );
    }

    const { fields: Fields, props } = step;
    return <Fields {...props} />;
};
