export const FR = {
    required: 'Ce champ est requis.',
    number: 'La valeur doit être un nombre.',
    string: 'La valeur doit être une chaîne de caractères.',
    min: (min) => `Min. ${min}`,
    max: (max) => `Max. ${max}`,
    invalidEmail: 'Adresse email non valide.',
    invalidPostalCode: 'Code postal non valide.',
    invalidPhone: 'Numéro non valide.',
    passwordTooWeak: 'Mot de passe trop simple.',
};

export const EN = {
    required: 'Required field.',
    number: 'Must be a valid number.',
    string: 'Must be a valid string.',
    min: (min) => `Min. ${min}`,
    max: (max) => `Max. ${max}`,
    invalidEmail: 'Invalid email address.',
    invalidPostalCode: 'Invalid postal code.',
    invalidPhone: 'Invalid phone number.',
    passwordTooWeak: 'Password too weak.',
};

export const formLanguage = {
    fr: FR,
    us: EN,
};
