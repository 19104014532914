export const FR = {
    titleEligibility: 'Vérifiez votre éligibilité en moins 2 de minutes',
    asterisk: `* Les résultats des simulations qui peuvent être réalisées sur ce site sont indicatifs et ne constituent pas des offres. L’acceptation finale de votre demande de travaux reste soumise à la validation de leur faisabilité technique et à l’étude de votre dossier.`,
};

export const EN = {
    titleEligibility: 'Check your eligibility in less than 2 minutes',
    asterisk: `* The results of the simulations that can be carried out on this site are indicative and do not constitute offers. The final acceptance of your work request remains subject to validation of their technical feasibility and the study of your file.`,
};

export const sidePanelLanguage = {
    fr: FR,
    us: EN,
};
