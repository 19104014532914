import { merge } from 'lodash';

import { pushDatas, redirectUrlDefault } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import {
    PERSONAL_INFOS_COMMON_STEP,
    mySecondRecurrentWay,
    CURRENT_VENTILATION_TYPES_COMMON_STEP,
} from './commonsSteps';

// 10.*.*
export const VENTILATE_HOME_STEPS = Object.freeze({
    [routesTitles.VENTILATE_HOME]: merge(
        { nextStep: '10.2', title: 'ventilateHome' },
        CURRENT_VENTILATION_TYPES_COMMON_STEP
    ),
    ...mySecondRecurrentWay(10),
    10.9: merge(
        {
            group: StepsGroups.infos,
            runFunction: pushDatas,
            replace: redirectUrlDefault,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
