import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ReactComponent as AddIcon } from '../../../../../assets/svg/add_circle.svg';
import { ReactComponent as RemoveIcon } from '../../../../../assets/svg/remove_circle.svg';
import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { FormField } from '../../../../commons/FormField/FormField';
import { TextField } from '../../../../designSystem/TextField/TextField';
import classes from './SmallNumberField.module.sass';

export const SmallNumberField = ({ labelTranslationKey, min = 0, max, step = 1, ...other }) => {
    const { language } = React.useContext(ApplicationContext);
    const { control } = useFormContext();

    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
    } = useController({
        name: 'value',
        control,
        defaultValue: 1,
    });

    const handlePreciseValueChange = React.useCallback(
        (addition) => () => {
            let result = value + addition;
            if (max !== undefined && result > max) {
                result = max;
            } else if (min !== undefined && result < min) {
                result = min;
            }
            onChange(result);
        },
        [onChange, value, min, max]
    );

    const handleTextFieldChange = React.useCallback(
        (event) => {
            const {
                target: { value: newValue },
            } = event;
            onChange(parseInt(newValue, 10));
        },
        [onChange]
    );

    return (
        <FormField {...{ error }} {...(labelTranslationKey && { label: language.steps[labelTranslationKey] })}>
            <div className={classes.root}>
                <button className={classes.iconButton} type="button" onClick={handlePreciseValueChange(-1)}>
                    <RemoveIcon className={classes.icon} />
                </button>
                <TextField
                    classes={{ root: classes.textField }}
                    type="number"
                    onChange={handleTextFieldChange}
                    {...{ onBlur, value, step, min, max }}
                    {...other}
                />
                <button className={classes.iconButton} type="button" onClick={handlePreciseValueChange(1)}>
                    <AddIcon className={classes.icon} />
                </button>
            </div>
        </FormField>
    );
};
