import cn from 'classnames';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../../../../../commons/Application/Application.context';
import { Choices } from '../../../../../designSystem/Choices/Choices';
import { Typography } from '../../../../../designSystem/Typography/Typography';
import classes from './FormChoices.module.sass';

export const FormChoices = React.memo(({ hasCategories, categories, multichoice, choices }) => {
    const { language, isSmall } = React.useContext(ApplicationContext);

    const { control } = useFormContext();
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name: 'value',
        control,
    });

    if (hasCategories) {
        return (
            <div className={cn(classes.categories, isSmall && classes.small)}>
                {Boolean(error) && <Typography classes={{ root: classes.error }}>{error.message}</Typography>}
                {Object.entries(categories).map(([categoryId, category]) => (
                    <div className={classes.category} key={`category_${categoryId}`}>
                        <Typography classes={{ root: classes.categoryTitle }} variant="subTitle2" component="h3">
                            {language.steps[category.translationKey]}
                        </Typography>
                        <Choices
                            classes={{ root: classes.choices }}
                            items={Object.entries(category.choices).map(([choiceId, choice]) => ({
                                value: choiceId,
                                label: language.steps[choice.translationKey],
                                icon: choice.icon,
                            }))}
                            {...{ onChange, value, multichoice }}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            {Boolean(error) && <Typography classes={{ root: classes.error }}>{error.message}</Typography>}
            <Choices
                items={Object.entries(choices).map(([choiceId, choice]) => ({
                    value: choiceId,
                    label: language.steps[choice.translationKey],
                    icon: choice.icon,
                }))}
                {...{ onChange, value, multichoice }}
            />
        </>
    );
});
