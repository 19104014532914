import cn from 'classnames';
import * as React from 'react';

import { Typography } from '../../designSystem/Typography/Typography';
import { StepsUtilsContext } from '../../routesComponents/Steps/Steps.context';
import { ApplicationContext } from '../Application/Application.context';
import classes from './Header.module.sass';

export const Header = ({ classes: receivedClasses = {} }) => {
    const { language } = React.useContext(ApplicationContext);
    const { title } = React.useContext(StepsUtilsContext);

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            <a
                {...{
                    href: 'https://www.flammebleue-environnement.fr/',
                    target: '_blank',
                    rel: 'noopener noreferrer',
                }}
            >
                <img className={classes.logo} src="/logo.png" alt="Flamme Bleue Environnement Logo" />
            </a>
            <Typography component="h4" classes={{ root: classes.typography }}>
                {language.titles[title]}
            </Typography>
        </div>
    );
};
