import { useSpring, animated } from '@react-spring/web';
import cn from 'classnames';
import React from 'react';

import { useCallbackOpen } from '../../../../../hooks/useCallbackOpen';
import classes from '../PersonalInfosFields/PersonalInfosFields.module.sass';
import { getCityButtonSpringProps } from './PostalCodeFields.springProps';

const CityButton = ({ onClick, city }) => {
    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();

    const buttonStyles = useSpring(getCityButtonSpringProps(isHovering));

    return (
        <div>
            <animated.button
                style={buttonStyles}
                type="button"
                onFocus={setIsHovering}
                onBlur={setIsNotHovering}
                onMouseEnter={setIsHovering}
                onMouseLeave={setIsNotHovering}
                className={cn(classes.list, classes.cityButton)}
                onClick={onClick}
            >
                {city}
            </animated.button>
        </div>
    );
};

export default CityButton;
