import { primary, secondary } from '../../../utils/variables';

const disabledColor = '#f6f6f6';

export const getButtonSpringProps = (disabled, isHovering) => ({
    backgroundImage: `linear-gradient(to right, ${disabled ? disabledColor : primary}, ${
        disabled ? disabledColor : secondary
    })`,
    boxShadow: `0 0 ${isHovering && !disabled ? 10 : 0}px ${secondary}`,
    color: disabled ? '#c0c0c0' : '#fff',
});
