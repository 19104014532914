import cn from 'classnames';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { TextField } from '../../../../designSystem/TextField/TextField';
import { Typography } from '../../../../designSystem/Typography/Typography';
import classes from './HeatingBillFields.module.sass';

export const HeatingBillFields = () => {
    const { language, isSmall } = React.useContext(ApplicationContext);
    const { register } = useFormContext();
    return (
        <div className={cn(classes.root, isSmall && classes.small)}>
            <TextField {...register('value')} afterInput="€" />
            <Typography classes={{ root: classes.typography }}>{language.steps['fields.heatingBill.every']}</Typography>
            <TextField component="select" {...register('unit')}>
                <option value="month">{language.steps['fields.heatingBill.unit.option.month']}</option>
                <option value="year">{language.steps['fields.heatingBill.unit.option.year']}</option>
            </TextField>
        </div>
    );
};
