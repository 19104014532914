export const routesTitles = {
    HOME: 'home',
    ISOLATION: 'isolation',
    CHANGE_HEATING: 'change-heating',
    APPLY_FOR_BONUS: 'apply-for-bonus',
    CALCULATE_AIDS: 'calculate-aids',
    HEAT_HOME_WATER: 'chauffer-l-eau-de-ma-maison',
    HEAT_HOME: 'chauffer-ma-maison',
    ISOLATE_HOME: 'isoler-ma-maison',
    REFRESH_HOME: 'rafraichir-ma-maison',
    RENOVE_HOME: 'renover-ma-maison',
    VENTILATE_HOME: 'ventiler-ma-maison',
    GENERAL_SHORT: 'general-short',
    ISOLATIONS: 'isolations',
    PAC: 'pac',
    GENERAL_INEGIBILITY: 'inegibility-general',
    GENERAL_ELIGIBILITY: 'eligibility-general',
    CALCUL_AIDS_RESULTS: 'calcul-aids-results',
};
