import { GoogleSpreadsheet } from 'google-spreadsheet';

// Config variables
const isUndefined = (value) => value === null || value === undefined || value === '';

const CLIENT_EMAIL = (elecBois) =>
    /* elecBois
        ? 't-630-689@western-notch-334309.iam.gserviceaccount.com'
        : */ 'demo-325@cg-form-328008.iam.gserviceaccount.com';
const PRIVATE_KEY = (elecBois) =>
    /* elecBois
        ? '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDU9EbfFGkiMQS/\nPhs4kRV1jfO2BjQpI2sop5OdQRxQGG+2kvrZQe7QbT49987B+2/i/0r396lFQt+q\n6gYLj8e0txgkzNyrd6lGxqVyjdKWxCv+cl//KwBBKeokfNejl0xIFpJuHIOYoLxE\nPoFTQIJd+XwyDcgCPmx/Z9xMvcgDi0TsaHWOdUh9/NyNsP6y3Pjcvc0H3Qxny5cV\n97d54tQUXhdmu5qaMhHqZ3A2sJjRTP1T14PS6RagWtkRyS3Ne4MUMtQX0Fwu7nOr\n961SxST1bcTs087hZgoSX4lOanCgeGSv1Kmjybg8V95EpmB8wCgAcphO3S/HbJRu\n8NpWZ2NRAgMBAAECggEABr0Rsdg5sqN62ZMqIovmWIlWYrZaV8ocIPXOJC+KqTb5\nif1BEAQX5Em6REgfWpOnQoQx9IJf8KYhR8Coa+mjBLy3RRE9rMG+yu0l3Rsc99Jb\nUtQYGSePyYAXCnKJrE6KuZk+2ici1UvFliJxzmP//BNIWWXtCoBV+vrnxX6xe1/4\nO1S0iNdVoJ+Kom7Otq+UMQTzVn/LSystgYq1cRg0ZY+OrLW3ymWjCO+yoZ6IoG7e\nF6DLDGzVxtZ84fwN+epdp78qtbhhdjTiD+n+r6ZKJpbY3Io0QMiLbJrU9cVJYUlh\npf33Zyxt4yM+9b61qWdFZ7Qzv1VgOpW97hNm69vxsQKBgQDwUxQ72UEfChwQSONr\nm05S5CwxZM5OaeXvMWova7IhMW18HbneHQ3rqdvZQDl2uV7fSWJ30YiSPTr/ByHc\nvaKAO9cPWTYgkGZ8vumYoydGS/YCnDGwuj7x6s7w2xTo6ajPxCqayVXznHA4lKja\nT8Yfm1NXdmv5Zci6LdCnvmx7ZQKBgQDi2CuUTF2n1zr6yTRvep9nEE1S0rDmdixI\nIlj4fRZeeqjIL6bxSMwUdGaCxwR4ziXZfhCtPzAaEUXCVM1kUrAa5ytMDdfI5CLa\nzjxyWboKNB8R6fGXrHLjeXnWLDMLtGVnButJcps8/MsFSMglTo/egDrMxA8BG4CV\nH4H9s8vnfQKBgFGRoTh/EN9cKxJL6wTG/dqcn3NUZ8Gtp+F7wDLANwGVKTgLTt7Y\ntbTbPkeKyPZHDLB00/sfH1+jcLbG0PN/Wfx0UxZejUSYPnDwy5CHL/loJuPT5/m3\n/U1XCU4uzfYP+MAsMNnApG+/FUffDUCr+u8XZT42b4eCyJKIol2NAH0RAoGAYENB\nww3hFI+WXMQzdzc/G7pkRchY/4OYaQgCX3idnHxK0aC/JFczG18F/Ws9L5KzpycD\nJrHQ1M8NnljkTDV2vAPrcLvTHVIKpHWuWwxOkXPq12xCdI3etz9ef4+EHexwt5bN\nE/8Jd4kDEnv/Sy2aid46lZiBkYO9Pgr/28yHs/UCgYEA09chY0CNP9cDdyFEYivL\nZ2j71fBSmtJeUK2zJ4E57h7HH5RJKH7niu52M2cfCT8rPUIMMZjlo+q69bIM0SWN\nnoqkMpK09z4fVZQdeP92Ig19nsuewCaT0v9gYWUBoYuTtvWeZj6Dd8PXw5p9jHU+\npXG7QLsvE57BOzu2KgjIK8M=\n-----END PRIVATE KEY-----\n'
        : */ '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDYittaZQokbsnR\nDqfPBYKtBnv87dgOZu67w93YYFKDXQA/KMhwFAU1LdyBOkBx6jYaMOCrhEobf8j9\n5ipGhnVCVCbYoQ3lh7E2PsQ+zN5NtcTdvAzKn2kPx8W8Ce1bs++duWqyNqTEU4Go\nzxnOKYVY2x11BK4snG+7hEAeKiwOKuJRslJQrNeummR0NYvA+TbQ0z5x0wCvxwvP\nmpVmVnNUklRTjckGpJgoP9+lfbk+mmx/0wnV9OrZsFcv0LDwk0hXIrhua+5ujIj6\nvMEtz7clGXiC4FRIVYhQXe9QuswVZUUDylzMGfjPgM7814c3c7PHLnWygNqmvxMF\nIC3lOEX5AgMBAAECggEACD8dhG9FosaPVrAABwZ23V+vrXMMtTEJVXWWR20U/LNi\n1NpDlopvT6umnUtFH1wj4afL3pb5UDEedpY4ikVeRio4l9ZmVj/ufpoxqhHuQ2Jb\n6XUwaC+y9hVjETqwOJD9MUdZlLONCdGuUJShLx9DWJ4bnJRoN622JEYwlOrWkQF8\n0bE36ACkoiVO+Bp+NjAT4jkLDpy2SgsC54SlagOxSN3W/RP3Uol2UGDHhNAUtI/o\nof6NyhQeTyGQLvhKaRkLCj5WA9QsBHtDqJgcrb9PnpgIU5wfd5akxsWmO57vNBax\nkw5zXVeXO9AyQsRhVk5AT9No6S0MMm0lOt+Y9Ia0OwKBgQDspH+NvMnfMAgY7Z0f\nlaXgAA+O5yM9HAdtbz1JoMSnaYmybg2H8+BsRiR9obRxLA3giKpU+LnksGroazKs\nnwPFl5dBX1/HGSgYhzNvSkPWrf8Gstb95kU11x7Ah6W77vFpBgBkEGyOCvmQkk/n\nwRukElyZ07pT+6ZtvlbhRKS8MwKBgQDqQXGVHa+EdQEPU112lChWZDe3Tdn7Zp7j\nBw3cmkGs0OHo3e63WrmNs3Y4U8xoeF+IRAis3f4BgLnepwc3NImqyjUxiHAos9kv\nUPE2npGIKVJjWkXqLudXghwV/KQmhoD0YKouxmrxfMUe5XsU2k6ywIY5A0/mD98N\nltfzSthJIwKBgQDnDZJxXNSqc6ZkWJTiguYLuWN3fxz8DML/ye5IVo3MfBJzQls5\n1Bfa//2fIOUi3wXJpwbQom426T3pIsm+nCA9rE6GCM5mgpyQnmnNDvD/yrHH45Rh\nIZWQBTPEfm2G+r2Bctes3BBx/CtCSml0eis33qnFlGJIn9wXKRKBLtm92wKBgEOf\nxtE8M5nySO8KqtV7abWe0rK45696bW2ucKL5aPIhjsWxo6MMrrooT31tO3oBHdrx\ngOueXiBC7+VFpICcSw2ht/9nmrE1UgIlxKx21B5Rh3Yk7j/vcFtDtSXnu99fPsTN\nTvOX1fGGikUkDAb2Cnfxug2lViBc/pAmtoqGVa+vAoGAb11ETraDWJNloZ2uy3TA\nyfIXw1OWf0OvMSDsvbcY9W86ZVBhvDnUxuunOm3qiomnhdbhwh/mq+zoF+3P5tbD\nB26j6LaG6xqxEBN3d32aAPNZQT9xQqb1+WP1WVeqNnqdWlKr7R8XSbfM88oAISXI\nDObYFow/7g9/HrJGBYkujkM=\n-----END PRIVATE KEY-----\n';

const convertToSheetFields = (rows, namesConverter) =>
    Object.entries(rows).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [namesConverter[key] || key]: value,
        }),
        {}
    );

const isElectriciteBois = (body) => {
    const isMatch = [
        'Une poêle à bois',
        'Des radiateurs électriques',
        'Chauffage à bois',
        'Chauffage au bois',
        'Poêle à bois (granulés ou bûches)',
        'Chaudière à bois',
        'Chauffe-eau électrique',
        'electricité',
        'Chauffage électrique',
        'chauffage_à_bois',
    ];
    if (Object.values(body).find((value) => isMatch.includes(value))) {
        return true;
    }
    return false;
};

const appendToMarketingSheet = async (rows) => {
    const namesConverter = {
        last_name: 'Nom',
        first_name: 'Prénom',
        phones: 'Téléphone',
        zipCode: 'CP',
        tag: 'Tag',
        date: 'Date',
        landlord_or_tenant: 'Situation',
        type_heating: 'Mode de chauffage',
        surface_habitable_chauffe: 'Surface',
        tax_income: 'Revenus',
        housing_type: 'Type de maison',
        isolation_needs_type: "Type d'isolation souhaitée",
        attics_type: 'Type de combles',
        garage_type: 'Type de garage',
        nbr_persons: 'NB Personnes foyer',
        statut: 'Statut',
        type_energie: "Type d'énergie",
        ventilation_mecanic_controlled: 'Ventilation mécanique controllée ?',
        accessible_lost_attics: 'Combles perdus accessibles ?',
        accessible_crawlspace: 'Vide sanitaire accessible ?',
        vs_hauteur: 'Hauteur vide sanitaire',
        levels_heat_count: 'Nombre niveaux chauffés',
        condensing_gaz_boiler: 'Chaudière à condensation ?',
        crawlspace_trap_contents: 'Le vide sanitaire contient-t-il une trappe ?',
        condensing_gaz_boiler_year: 'Année de la chaudière à condensation',
        refresh_energy_type: "Type d'énergie de refroidissement",
        surface_mur: 'Surface du mur',
        basement_isolation_type: 'Isolation du sous-sol',
        height_under_ceiling: 'Hauteur sous plafond',
        work_type: 'Type de travaux',
        acceptConditions: 'Conditions acceptation données collectées',
        value: 'Valeur',
        civilite: 'Civilité',
        full_name: 'Nom complet',
        emails: 'Email',
        city: 'Ville',
        campaign: 'campaign',
        utmSource: 'utmSource',
        utmParameters: 'utmParameters',
        lost_attics_height_under_ceiling: 'Hauteur sous le plafond des combles perdus',
        lost_attics_isolation_surface: 'Surface des combles perdus',
        converted_attics_height_under_ceiling: 'Hauteur sous le plafond des combles aménagés',
        converted_attics_isolation_surface: 'Surface des combles aménagés',
        lost_and_converted_attics_height_under_ceiling: 'Hauteur sous le plafond des combles perdus et aménagés',
        lost_and_converted_attics_isolation_surface: 'Surface des combles perdus et aménagés',
        crawlspace_isolation_surface: 'Surface du vide sanitaire',
        basement_isolation_surface: 'Surface de la cave',
        garage_isolation_surface: 'Surface du garage',
        interior_isolation_surface: 'Surface des murs intérieurs',
        crawlspace_height_under_ceiling: 'Hauteur sous le plafond du vide sanitaire',
        basement_height_under_ceiling: 'Hauteur sous le plafond de la cave',
        garage_height_under_ceiling: 'Hauteur sous le plafond du garage',
        interior_height_under_ceiling: 'Hauteur sous le plafond des murs intérieurs',
        JSON: 'JSON',
        primes: 'Primes',
        profite_iso: 'Isolation à 1euro',
    };
    const sheetsRows = convertToSheetFields(rows, namesConverter);
    const SPREADSHEET_ID =
        /* isElectriciteBois(sheetsRows)
        ? '1Ref4EzoWsCbSIiJCGpu-TwvEYdmgl0WPFDYvOjJdZG8'
        : */ '1EzKVR50APs9acatMJY23F2IrBh8M0WNScCMuz1IN9ts';
    const SHEET_ID = /* isElectriciteBois(sheetsRows) ? '0' : */ '775419761';
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    try {
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL(isElectriciteBois(sheetsRows)),
            private_key: PRIVATE_KEY(isElectriciteBois(sheetsRows)),
        });
        // loads document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsById[SHEET_ID];
        await sheet.addRow(sheetsRows);
    } catch (e) {
        console.error('appendToMarketingSheetError: ', e);
        throw e;
    }
};

const appendToLeadsSheet = async (rows) => {
    const namesConverter = {
        emails: 'Email',
        last_name: 'Nom',
        first_name: 'Prénom',
        phones: 'Téléphone',
        zipCode: 'CP',
        fullDate: 'Date',
        type_heating: 'Chauffage',
        surface_habitable_chauffe: 'Surface',
        tax_income: 'Revenus',
        nbr_persons: 'NB Personnes foyer',
        city: 'Ville',
        tag: 'Tag',
        day: 'Jour',
        month: 'Mois',
        year: 'Année',
        primes: 'Primes',
        profite_iso: 'Avez-vous deja /',
    };
    const sheetsRows = convertToSheetFields(rows, namesConverter);
    /* if (isElectriciteBois(sheetsRows)) {
        return;
    } */
    const SPREADSHEET_ID = '1pVvZvdg4EimJ4szNAve0SWBEWnaf2aB28PwnebuhbJ4';
    const SHEET_ID = '1822834';
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    try {
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL(isElectriciteBois(sheetsRows)),
            private_key: PRIVATE_KEY(isElectriciteBois(sheetsRows)),
        });
        // loads document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsById[SHEET_ID];
        const rowsFromSheet = await sheet.getRows();
        const lastRowIndex = rowsFromSheet.length - 1;
        sheetsRows.ID = 1;
        sheetsRows.Source = 'GRENNOPE';
        if (lastRowIndex > -1) {
            const lastRow = rowsFromSheet[lastRowIndex];
            sheetsRows.ID = Number(lastRow.ID) + 1;
        }
        await sheet.addRow(sheetsRows);
        return;
    } catch (e) {
        console.error('appendToLeadsSheetError: ', e);
        throw e;
    }
};

export const appendSpreadsheet = async (rows) => {
    await appendToMarketingSheet(rows);
    await appendToLeadsSheet(rows);
};

// const newRow = {
//     "work_type": "Isoler ma maison",
//     "housing_type": "Maison",
//     "value": "Propriétaire",
//     "isolation_needs_type": "Un garage",
//     "garage_type": "Garage interne",
//     "type_energie": "Chauffage électrique",
//     "zipCode": "75012",
//     "city": "PARIS 12",
//     "nbr_persons": 1,
//     "tax_income": "Inférieur à 30 225 €",
//     "first_name": "Jeremy",
//     "last_name": "Lazimi",
//     "phones": "0630269785",
//     "emails": "jeremy.lazimi@gmail.com",
//     "civilite": "male",
//     "full_name": "Jeremy Lazimi",
//     "campaign": "home",
//     "tag": "Général",
//     "utmParameters": "",
//     "garage_isolation_surface": 21343
// };

// appendSpreadsheet(newRow);
