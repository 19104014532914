import React from 'react';
import ReactDOM from 'react-dom';

import { Application } from './components/commons/Application/Application';

import './styles/global.sass';

ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <Application />,
    // eslint-disable-next-line no-undef
    document.getElementById('root')
);
