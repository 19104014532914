export const revenuAboutNumbersOfPersons = (idf = false) =>
    idf
        ? [
              [20593, 25068, 38184],
              [30225, 36792, 38184],
              [36297, 44188, 67585],
              [42381, 51597, 79041],
              [48488, 59026, 90496],
          ]
        : [
              [14879, 19074, 29148],
              [21760, 27896, 42848],
              [26170, 33547, 51592],
              [30572, 39192, 60336],
              [34993, 44860, 69081],
          ];

export const revenuAboutNumbersOfPersonsAdditionnal = (idf = false) => (idf ? [6096, 7422, 11455] : [4412, 5651, 8744]);

export const travaux = {
    globale: [true, true, 7000, 3500],
    woodStove: [3873, 3373, 2045, 545],
    airWaterHeatPump: [14364, 7364, 4727, 2727],
    thermodynamicWaterHeater: [1368, 884, 484, 84],
    individualSolarWaterHeater: [4000, 3000, 2000, false],
    VMC: [4441, 3220, 2220, 220],
    walls: [112, 78, 58, 33],
    flatRoof: [96, 71, 51, 26],
    attics: [22, 22, 11, 11],
};

export const translationNames = {
    wallsIsolationSurface: 'surface_mur',
};
