import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { HOUSING_TYPES_COMMON_STEP, LANDLORD_OR_TENANT_COMMON_STEP, PERSONAL_INFOS_COMMON_STEP } from './commonsSteps';

// 11.*.*
export const GENERAL_SHORT_STEPS = Object.freeze({
    [routesTitles.GENERAL_SHORT]: merge(
        {
            title: 'shortHome',
            choices: {
                house: { nextStep: '11.2' },
                apartment: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        HOUSING_TYPES_COMMON_STEP
    ),
    11.2: merge(
        {
            choices: {
                landlord: { nextStep: '11.3' },
                tenant: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        LANDLORD_OR_TENANT_COMMON_STEP
    ),
    11.3: merge(
        {
            group: StepsGroups.infos,
            runFunction: pushDatas,
            replace: redirectUrlDefault,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
