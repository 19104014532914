export const getCurrentStepTransitions = (wentToPreviousStep) => ({
    from: {
        transform: `translate3d(${wentToPreviousStep ? -50 : 50}px, 0, 0)`,
        opacity: 0,
    },
    enter: {
        transform: 'translate3d(0px, 0, 0)',
        opacity: 1,
    },
    leave: {
        transform: `translate3d(${wentToPreviousStep ? 50 : -50}px, 0, 0)`,
        opacity: 0,
    },
});
