/* eslint-disable no-restricted-syntax */
import { isNumber } from 'lodash';

import { travaux, translationNames } from './variables';

export const isIdf = (cp) => {
    const dep = Number(cp.slice(0, 2));
    switch (dep) {
        case 75:
        case 77:
        case 78:
        case 91:
        case 92:
        case 93:
        case 94:
        case 95:
            return true;
        default:
            return false;
    }
    return false;
};

export const isGlobale = (arr) => {
    const isolations = ['attics', 'walls', 'floors', 'windows', 'flatRoof'];
    const pacs = ['airWaterHeatPump', 'geothermalHeatPump', 'airAirHeatPump', 'hybridHeatPump'];
    const isIsolation = () => {
        for (const elem of arr) {
            if (isolations.includes(elem)) {
                return true;
            }
        }
        return false;
    };
    const isPacs = () => {
        for (const elem of arr) {
            if (pacs.includes(elem)) {
                return true;
            }
        }
        return false;
    };

    return isIsolation() && isPacs();
};

const isos = ['walls', 'flatRoof', 'attics'];

const getIsolationElementSurface = (elem, body) =>
    body[`${elem}IsolationSurface`]
        ? body[`${elem}IsolationSurface`]
        : translationNames[`${elem}IsolationSurface`]
        ? body[translationNames[`${elem}IsolationSurface`]]
        : 1;

export const getPrimes = (arr, index, body) => {
    if (isGlobale(arr)) {
        return travaux.globale[index];
    }
    let primes = 0;
    for (const [key, value] of Object.entries(travaux)) {
        if (arr.includes(key)) {
            if (isos.includes(key)) {
                primes += value[index] * getIsolationElementSurface(key, body);
            } else if (isNumber(value[index])) {
                primes += value[index];
            }
        }
    }
    return primes;
};
