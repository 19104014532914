import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { PERSONAL_INFOS_COMMON_STEP, myFirstRecurrentWay } from './commonsSteps';

// 5.*.*

export const ISOLATE_HOME_STEPS = Object.freeze({
    ...myFirstRecurrentWay(5, routesTitles.ISOLATE_HOME, { title: 'isolateHome' }),
    5.9: merge(
        {
            group: StepsGroups.infos,
            runFunction: pushDatas,
            replace: redirectUrlDefault,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
