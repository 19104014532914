import * as yup from 'yup';

export const getPostalCodeSchema = (translations) =>
    yup.object().shape({
        zipCode: yup
            .string()
            .required(translations.required)
            .matches(
                /^(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})$/,
                translations.invalidPostalCode
            ),
        city: yup.string().required(translations.required).min(2, translations.min(2)),
    });
