import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { FormField } from '../../../../commons/FormField/FormField';
import { Choices } from '../../../../designSystem/Choices/Choices';
import { TextField } from '../../../../designSystem/TextField/TextField';
import classes from './PersonalInfosFields.module.sass';

export const PersonalInfosFields = ({ ...other }) => {
    const { language } = React.useContext(ApplicationContext);
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <Controller
                name="civilite"
                {...{ control }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField classes={{ root: classes.formField }} {...{ error }}>
                        <Choices
                            items={[
                                {
                                    value: 'M',
                                    label: language.steps['fields.personalInfos.gender.option.male'],
                                },
                                {
                                    value: 'Mme',
                                    label: language.steps['fields.personalInfos.gender.option.female'],
                                },
                            ]}
                            {...{ onChange, value }}
                        />
                    </FormField>
                )}
            />
            <div className={classes.behindFieldsContainer}>
                <FormField
                    classes={{ root: classes.formField }}
                    label={language.steps['fields.personalInfos.firstName.label']}
                    error={errors.firstName}
                >
                    <TextField {...other} {...register('first_name')} />
                </FormField>
                <FormField
                    classes={{ root: classes.formField }}
                    label={language.steps['fields.personalInfos.lastName.label']}
                    error={errors.lastName}
                >
                    <TextField {...other} {...register('last_name')} />
                </FormField>
            </div>
            <FormField
                classes={{ root: classes.formField }}
                label={language.steps['fields.personalInfos.phone.label']}
                error={errors.phone}
            >
                <TextField {...other} {...register('phones')} />
            </FormField>
            <FormField
                classes={{ root: classes.formField }}
                label={language.steps['contents.authentication.commons.fields.email.label']}
                error={errors.email}
            >
                <TextField {...register('emails')} />
            </FormField>
        </>
    );
};
