import { merge } from 'lodash';

import { pushDatas } from '../../../../helpers';
import { urlEndGeneral } from '../../../../utils/variables';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { PERSONAL_INFOS_COMMON_STEP, myFirstRecurrentWay } from './commonsSteps';

// 1.*.*

export const ISOLATION_STEPS = Object.freeze({
    ...myFirstRecurrentWay(1, routesTitles.ISOLATION),
    1.9: merge(
        {
            group: StepsGroups.infos,
            // nextStep: routesTitles.GENERAL_ELIGIBILITY,
            replace: urlEndGeneral,
            runFunction: pushDatas,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
    // 1.8: {
    //     group: StepsGroups.infos,
    //     type: 'content',
    //     content: Authentication,
    //     nextStep: '1.9',
    // },
});
