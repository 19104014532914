import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../commons/Application/Application.context';
import { Typography } from '../../../designSystem/Typography/Typography';
import { Groups } from '../Groups/Groups';
import { StepsUtilsContext } from '../Steps.context';
import classes from './SidePanel.module.sass';

export const SidePanel = ({ classes: receivedClasses = {} }) => {
    const { language } = React.useContext(ApplicationContext);
    const { sideTitle } = React.useContext(StepsUtilsContext);

    return (
        <div className={cn(classes.root, receivedClasses.root)}>
            <Typography component="p" classes={{ root: classes.title }}>
                {language.sidePanel[sideTitle]}
            </Typography>
            <Groups classes={{ root: classes.groups }} />
            <Typography component="p" classes={{ root: classes.smallText }}>
                {language.sidePanel.asterisk}
            </Typography>
        </div>
    );
};
