import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router } from 'react-router-dom';

import languages from '../../../languages';
import { Routes } from '../../routes/Routes/Routes';
import CookieWarning from '../CookieWarning/CookieWarning';
import { ApplicationContext } from './Application.context';

/*
If you just need to integrate Steps in an existing React project :
Add routes to existing router.
Move 'isSmall' & 'language' in another context / store.
*/

export const Application = () => {
    const isSmall = useMediaQuery({
        query: '(max-width: 950px)',
    });

    // Avoid useless re-renders.
    const contextValue = React.useMemo(
        () => ({
            isSmall,
            language: languages.fr,
        }),
        [isSmall]
    );

    const app = () => {
        try {
            if (localStorage) {
                return (
                    <ApplicationContext.Provider value={contextValue}>
                        <Router>
                            <Routes />
                        </Router>
                    </ApplicationContext.Provider>
                );
            }
        } catch (err) {
            return <CookieWarning />;
        }
        return <CookieWarning />;
    };

    return app();
};
