import { merge } from 'lodash';

import { pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { CalculAids } from '../contents/CalculAids/CalculAids';
import { InstallHeatingFromPartners } from '../contents/InstallHeatingFromPartners/InstallHeatingFromPartners';
import { IsolationEligibility } from '../contents/IsolationEligibility/IsolationEligibility';
import { IsolationInegibility } from '../contents/IsolationInegibility/IsolationInegibility';
import { PersonalInfosFields } from '../fields/PersonalInfosFields/PersonalInfosFields';
import { PostalCodeFields } from '../fields/PostalCodeFields/PostalCodeFields';
import { SimpleField } from '../fields/SimpleField/SimpleField';
import { SmallNumberField } from '../fields/SmallNumberField/SmallNumberField';
import { getDefaultSchema } from '../schemas/default.schema';
import { getPersonalInfosSchema } from '../schemas/personalInfos.schema';
import { getPostalCodeSchema } from '../schemas/postalCode.schema';

/*
Commons steps are steps which can appear multiple times throughout the process.
They don't have 'group' and 'nextStep' properties and need to be merged when
used. They are sorted by first appearance.
*/

// Ex: isolation
export const HOUSING_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.housingTypes.title',
    type: 'choices',
    name: 'housing_type',
    choices: {
        house: {
            icon: '/assets/svg/house.svg',
            translationKey: 'commons.housingTypes.choices.house',
        },
        apartment: {
            icon: '/assets/svg/apartment.svg',
            translationKey: 'commons.housingTypes.choices.apartment',
        },
    },
});

export const RENOVATE_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.needs,
    titleTranslationKey: 'commons.renovateNeeds.title',
    type: 'choices',
    choices: {
        changeHeating: {
            icon: '/assets/svg/radiator.svg',
            translationKey: 'home.choices.changeHeating',
        },
        ECS: {
            icon: '/assets/svg/boiler.svg',
            translationKey: 'commons.renovateNeeds.choices.ECS',
        },
        isolateHouse: {
            icon: '/assets/svg/house.svg',
            translationKey: 'home.choices.isolateHouse',
        },
    },
});

// 1.2 | 5.3
export const ISOLATION_SELECTION_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.isolationSelection.title',
    name: 'isolation_needs_type',
    type: 'choices',
    choices: {
        attics: {
            icon: '/assets/svg/attics.svg',
            translationKey: 'commons.isolationSelection.choices.attics',
        },
        garage: {
            icon: '/assets/svg/garage.svg',
            translationKey: 'commons.isolationSelection.choices.garage',
        },
        basement: {
            icon: '/assets/svg/basement.svg',
            translationKey: 'commons.isolationSelection.choices.basement',
        },
        crawlspace: {
            icon: '/assets/svg/crawlspace.svg',
            translationKey: 'commons.isolationSelection.choices.crawlspace',
        },
        interior: {
            icon: '/assets/svg/interior-isolation.svg',
            translationKey: 'commons.isolationSelection.choices.interior',
        },
    },
});

// Ex: 1.2.1 | 3.7.1 | 4.9.1
export const ATTICS_ISOLATION_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.atticsIsolationTypes.title',
    name: 'attics_type',
    type: 'choices',
    requiredNextStep: 'ATTICS_ISOLATION_SURFACE_COMMON_STEP',
    choices: {
        lostAttics: {
            icon: '/assets/svg/converted-attics.svg',
            translationKey: 'commons.atticsIsolationTypes.choices.lostAttics',
        },
        convertedAttics: {
            icon: '/assets/svg/lost-attics.svg',
            translationKey: 'commons.atticsIsolationTypes.choices.convertedAttics',
        },
        multipleAttics: {
            icon: '/assets/svg/multiple-attics.svg',
            translationKey: 'commons.atticsIsolationTypes.choices.multipleAttics',
        },
    },
});

export const GARAGE_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.garageType.title',
    name: 'garage_type',
    type: 'choices',
    choices: {
        lostAttics: {
            icon: '/assets/svg/intern-garage.svg',
            translationKey: 'commons.garageType.choices.intern',
        },
        convertedAttics: {
            icon: '/assets/svg/extern-garage.svg',
            translationKey: 'commons.garageType.choices.extern',
        },
    },
});

// Ex: 1.3.1 | 3.7.1.1 | 4.9.1.1
export const ATTICS_ISOLATION_SURFACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.atticsIsolationSurface.title',
    name: 'atticsIsolationSurface',
    type: 'fields',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surface.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 1.3.2 | 3.7.3.1 | 4.9.3.1
export const BASEMENT_ISOLATION_SURFACE_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.basementIsolationSurface.title',
    group: StepsGroups.project,
    name: 'basementIsolationSurface',
    type: 'fields',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surface.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 1.5 | 2.9 | 3.11
export const HOUSING_MEMBERS_COUNT_COMMON_STEP = Object.freeze({
    group: StepsGroups.infos,
    titleTranslationKey: 'commons.housingMembersCount.title',
    name: 'nbr_persons',
    type: 'fields',
    fields: SmallNumberField,
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 3.7.7
export const RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.radiatorsToInstallOrReplaceCount.title',
    type: 'fields',
    fields: SmallNumberField,
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 1.7 | 3.12
export const PERSONAL_INFOS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.personalInfos.title',
    subTitleTranslationKey: 'commons.personalInfos.subTitle',
    miniSubTitle: true,
    type: 'fields',
    fields: PersonalInfosFields,
    getValidationSchema: getPersonalInfosSchema,
    getShouldButtonBeDisabled: (currentStepValues) =>
        ['civilite', 'first_name', 'last_name', 'phones', 'emails'].some((key) => !currentStepValues[key]),
    runFunction: pushDatas,
    buttonText: 'validate',
});

// 2.3 | home-heat-water
export const LANDLORD_OR_TENANT_COMMON_STEP = Object.freeze({
    title: 'isolation',
    sideTitle: 'titleEligibility',
    group: StepsGroups.project,
    titleTranslationKey: 'commons.landlordOrTenant.title',
    type: 'choices',
    name: 'landlord_or_tenant',
    choices: {
        landlord: {
            icon: '/assets/svg/landlord.svg',
            translationKey: 'commons.landlordOrTenant.choices.landlord',
        },
        tenant: {
            icon: '/assets/svg/tenant.svg',
            translationKey: 'commons.landlordOrTenant.choices.tenant',
        },
    },
});

// Ex: 2.5.1 | 3.2.1
export const NOT_ELIGIBLE_FOR_HEATING_OFFERS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.notEligibleForHeatingOffers.title',
    subTitleTranslationKey: 'commons.notEligibleForHeatingOffers.subTitle',
    type: 'content',
    content: InstallHeatingFromPartners,
});

export const GENERAL_EGIBILITY_COMMON_STEP = Object.freeze({
    group: StepsGroups.eligibility,
    titleTranslationKey: '1.8.title',
    subTitleTranslationKey: 'commons.notEligibleForHeatingOffers.subTitle',
    type: 'content',
    content: IsolationEligibility,
    runFunction: pushDatas,
});

export const GENERAL_INEGIBILITY_COMMON_STEP = Object.freeze({
    group: StepsGroups.eligibility,
    titleTranslationKey: 'commons.notEligibleGeneral.title',
    type: 'content',
    content: IsolationInegibility,
    runFunction: pushDatas,
});

export const CALCUL_AIDS_COMMON_STEP = Object.freeze({
    group: StepsGroups.eligibility,
    titleTranslationKey: 'commons.eligibilityAids.title',
    coloredLastTitle: {
        color: '#1C7AB5',
        title: 'commons.eligibilityAids.titleBonus',
    },
    type: 'content',
    content: CalculAids,
    runFunction: pushDatas,
});

// Ex: 2.6
export const CURRENT_HOUSING_ENERGY_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.currentHousingEnergy.title',
    subTitleTranslationKey: 'commons.currentHousingEnergy.subTitle',
    type: 'choices',
    name: 'type_energie',
    choices: {
        oilHeating: {
            icon: '/assets/svg/oil.svg',
            translationKey: 'commons.currentHousingEnergy.choices.oilHeating',
        },
        electricHeating: {
            icon: '/assets/svg/electric.svg',
            translationKey: 'commons.currentHousingEnergy.choices.electricHeating',
        },
        coalHeating: {
            icon: '/assets/svg/coal.svg',
            translationKey: 'commons.currentHousingEnergy.choices.coalHeating',
        },
        woodHeating: {
            icon: '/assets/svg/wood.svg',
            translationKey: 'commons.currentHousingEnergy.choices.woodHeating',
        },
        heatPump: {
            icon: '/assets/svg/heat-pump.svg',
            translationKey: 'commons.currentHousingEnergy.choices.heatPump',
        },
        gasHeating: {
            icon: '/assets/svg/gas.svg',
            translationKey: 'commons.currentHousingEnergy.choices.gasHeating',
        },
    },
});

export const CURRENT_VENTILATION_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.ventilationTypes.title',
    name: 'ventilation_mecanic_controlled',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const ISOLATION_ONE_EURO_ALREADY_DONE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.isolationOneEuroAlreadyDone.title',
    subTitleTranslationKey: 'commons.isolationOneEuroAlreadyDone.subTitle',
    name: 'profite_iso',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const ACCESSIBLE_ATTICS_LOSTED_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.accessibleLostAttics.title',
    name: 'accessible_lost_attics',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const ACCESSIBLE_CRAWLSPACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.accessibleCrawlspaceAttics.title',
    name: 'accessible_crawlspace',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});
export const CRAWLSPACE_HEIGHT_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.crawlspaceHeight.title',
    name: 'vs_hauteur',
    type: `fields`,
    fields: SimpleField,
    props: {
        labelTranslationKey: `fields.meterMeasure.value.label`,
    },
    getValidationSchema: getDefaultSchema(`number`, 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 2.7.1
export const OIL_EQUIPMENTS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.oilEquipments.title',
    type: 'choices',
    choices: {
        condensingHeating: {
            icon: '/assets/svg/condensing-heating.svg',
            translationKey: 'commons.oilEquipments.choices.condensingHeating',
        },
        otherThanCondensingHeating: {
            icon: '/assets/svg/heating.svg',
            translationKey: 'commons.oilEquipments.choices.otherThanCondensingHeating',
        },
        stove: {
            icon: '/assets/svg/stove.svg',
            translationKey: 'commons.oilEquipments.choices.stove',
        },
    },
});

// Ex: 2.7.2
export const ELECTRIC_EQUIPMENTS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.electricEquipments.title',
    type: 'choices',
    choices: {
        electricHeating: {
            icon: '/assets/svg/electric-heating.svg',
            translationKey: 'commons.electricEquipments.choices.electricHeating',
        },
        heatedCeilingsOrFloors: {
            icon: '/assets/svg/heated-ceilings-or-floors.svg',
            translationKey: 'commons.electricEquipments.choices.heatedCeilingsOrFloors',
        },
        electricHeater: {
            icon: '/assets/svg/electric-radiator.svg',
            translationKey: 'commons.electricEquipments.choices.electricHeater',
        },
    },
});

// Ex: 2.7.3
export const GAS_EQUIPMENTS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.gasEquipments.title',
    type: 'choices',
    choices: {
        condensingHeating: {
            icon: '/assets/svg/condensing-heating.svg',
            translationKey: 'commons.gasEquipments.choices.condensingHeating',
        },
        condensingHeating2: {
            icon: '/assets/svg/heating.svg',
            translationKey: 'commons.gasEquipments.choices.condensingHeating2',
        },
        gasRadiator: {
            icon: '/assets/svg/gas-radiator.svg',
            translationKey: 'commons.gasEquipments.choices.gasRadiator',
        },
        gasStove: {
            icon: '/assets/svg/gas-stove.svg',
            translationKey: 'commons.gasEquipments.choices.gasStove',
        },
    },
});

// Ex: 2.8 | 4.4
export const HOUSING_SURFACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    type: 'fields',
    titleTranslationKey: 'commons.housingSurface.title',
    name: 'surface_habitable_chauffe',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surfaceHabitable.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

export const HOW_MANY_LEVELS_HEAT_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    type: 'fields',
    name: 'levels_heat_count',
    titleTranslationKey: 'commons.howManylevelsHeat.title',
    subTitleTranslationKey: 'commons.howManylevelsHeat.subTitle',
    fields: SmallNumberField,
    getValidationSchema: getDefaultSchema('number'),
    getShouldButtonBeDisabled: (currentStepValues) => currentStepValues.value < 0,
});

export const TAX_INCOME_NUMBER_COMMON_STEP = Object.freeze({
    group: StepsGroups.infos,
    titleTranslationKey: 'commons.taxIncomeNumber.title',
    subTitleTranslationKey: 'commons.taxIncome.subTitle',
    type: 'fields',
    name: 'taxIncomeNumber',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.taxIncome.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 2.10 | 3.12 | 5.8
export const TAX_INCOME_COMMON_STEP = Object.freeze({
    group: StepsGroups.infos,
    titleTranslationKey: 'commons.taxIncome.title',
    subTitleTranslationKey: 'commons.taxIncome.subTitle',
    name: 'tax_income',
    type: 'choices',
    choices: {
        lessThanX: {
            icon: '/assets/svg/tax-income-0.svg',
            translationKey: 'commons.taxIncome.choices.lessThanX',
        },
        betweenXAndY: {
            icon: '/assets/svg/tax-income-1.svg',
            translationKey: 'commons.taxIncome.choices.betweenXAndY',
        },
        betweenYAndZ: {
            icon: '/assets/svg/tax-income-2.svg',
            translationKey: 'commons.taxIncome.choices.betweenYAndZ',
        },
        moreThanZ: {
            icon: '/assets/svg/tax-income-3.svg',
            translationKey: 'commons.taxIncome.choices.moreThanZ',
        },
    },
});

// 2.12 | 3.14
export const ELIGIBLE_FOR_BONUS_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.eligibleForBonus.title',
    type: 'content',
    content: () => 'YES',
    runFunction: pushDatas,
});

// Ex: 3.2
export const IS_OLDER_THAN_TWO_YEARS_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.isOlderThanTwoYears.title',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const CONDENSING_GAZ_BOILER_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.condensingGazBoiler.title',
    type: 'choices',
    name: 'condensing_gaz_boiler',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const CRAWLSPACE_TRAP_CONTENTS_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.crawlspaceTrapContents.title',
    name: 'crawlspace_trap_contents',
    type: 'choices',
    choices: {
        yes: {
            icon: '/assets/svg/yes.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.yes',
        },
        no: {
            icon: '/assets/svg/no.svg',
            translationKey: 'commons.isOlderThanTwoYears.choices.no',
        },
    },
});

export const CONDENSING_GAZ_BOILER_DATE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.condensingGazBoilerDate.title',
    name: 'condensing_gaz_boiler_year',
    type: 'fields',
    fields: SimpleField,
    props: {
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

export const ENERGY_HOME_REFRESH_TYPE_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.currentHousingEnergyRefresh.title',
    group: StepsGroups.project,
    name: 'refresh_energy_type',
    type: 'choices',
    choices: {
        airConditionerInteger: {
            icon: '/assets/svg/air-air-heat-pump.svg',
            translationKey: 'commons.works.categories.heatPump.choices.airConditionerIntern',
        },
        airConditionerPortable: {
            icon: '/assets/svg/airConditionerPortable.svg',
            translationKey: 'commons.works.categories.heatPump.choices.airConditionerPortable',
        },
        heatPump: {
            icon: '/assets/svg/heat-pump.svg',
            translationKey: '2.1.choices.heatPump',
            nextStep: '2.2',
        },
        none: {
            icon: '/assets/svg/no.svg',
            translationKey: '2.1.choices.none',
            nextStep: '2.2',
        },
    },
});

// Ex: 3.6.2
export const APARTMENT_WORKS_COMMON_STEP = Object.freeze({
    name: 'worksWishCategories',
    titleTranslationKey: 'commons.works.title',
    type: 'choices',
    hasCategories: true,
    multichoice: true,
    categories: {
        isolationVentilation: {
            translationKey: 'commons.works.categories.isolationVentilation.title',
            choices: {
                walls: {
                    icon: '/assets/svg/walls.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.walls',
                    requiredNextStep: 'WALLS_ISOLATION_TYPES_COMMON_STEP',
                },
                windows: {
                    icon: '/assets/svg/windows.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.windows',
                    requiredNextStep: 'WINDOWS_ISOLATION_COUNT_COMMON_STEP',
                },
                flatRoof: {
                    icon: '/assets/svg/flat-roof.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.flatRoof',
                    requiredNextStep: 'ROOF_ISOLATION_SURFACE_COMMON_STEP',
                },
            },
        },
        heatPump: {
            translationKey: 'commons.works.categories.heatPump.title',
            choices: {
                airWaterHeatPump: {
                    icon: '/assets/svg/heat-pump.svg',
                    translationKey: 'commons.works.categories.heatPump.choices.airWaterHeatPump',
                    requiredNextStep: 'HEAT_PUMP_TYPES_COMMON_STEP',
                },
                geothermalHeatPump: {
                    icon: '/assets/svg/geothermal-heat-pump.svg',
                    translationKey: 'commons.works.categories.heatPump.choices.geothermalHeatPump',
                    requiredNextStep: 'HEAT_PUMP_TYPES_COMMON_STEP',
                },
                airAirHeatPump: {
                    icon: '/assets/svg/air-air-heat-pump.svg',
                    translationKey: 'commons.works.categories.heatPump.choices.airAirHeatPump',
                },
                hybridHeatPump: {
                    icon: '/assets/svg/hybrid-heat-pump.svg',
                    translationKey: 'commons.works.categories.heatPump.choices.hybridHeatPump',
                    requiredNextStep: 'HEAT_PUMP_TYPES_COMMON_STEP',
                },
            },
        },
        traditionalHeating: {
            translationKey: 'commons.works.categories.traditionalHeating.title',
            choices: {
                oilCondensingBoiler: {
                    icon: '/assets/svg/condensing-heating.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.oilCondensingBoiler',
                },
                gasCondensingBoiler: {
                    icon: '/assets/svg/gas-condensing-heating.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.gasCondensingBoiler',
                },
                inertiaRadiator: {
                    icon: '/assets/svg/inertia-radiator.svg',
                    translationKey: 'commons.works.categories.isolationVentilation.choices.inertiaRadiator',
                    requiredNextStep: 'RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP',
                },
            },
        },
        waterHeater: {
            translationKey: 'commons.works.categories.waterHeater.title',
            choices: {
                thermodynamicWaterHeater: {
                    icon: '/assets/svg/thermodynamic-water-boiler.svg',
                    translationKey: 'commons.works.categories.waterHeater.choices.thermodynamicWaterHeater',
                },
            },
        },
    },
});

// House works (Ex: 3.6.1) contains the same choices as apartment but with some more.
export const HOUSE_WORKS_COMMON_STEP = merge(
    {
        categories: {
            isolationVentilation: {
                choices: {
                    attics: {
                        icon: '/assets/svg/attics.svg',
                        translationKey: 'commons.works.categories.isolationVentilation.choices.attics',
                        requiredNextStep: 'ATTICS_ISOLATION_TYPES_COMMON_STEP',
                    },
                    floors: {
                        icon: '/assets/svg/floors.svg',
                        translationKey: 'commons.works.categories.isolationVentilation.choices.floors',
                        requiredNextStep: 'BASEMENT_ISOLATION_TYPES_COMMON_STEP',
                    },
                },
            },
            woodHeating: {
                translationKey: 'commons.works.categories.woodHeating.title',
                choices: {
                    fireplaceInsert: {
                        icon: '/assets/svg/fireplace-insert.svg',
                        translationKey: 'commons.works.categories.woodHeating.choices.fireplaceInsert',
                    },
                    woodStove: {
                        icon: '/assets/svg/wood-stove.svg',
                        translationKey: 'commons.works.categories.woodHeating.choices.woodStove',
                    },
                    woodBoiler: {
                        icon: '/assets/svg/wood-boiler.svg',
                        translationKey: 'commons.works.categories.woodHeating.choices.woodBoiler',
                    },
                },
            },
            solar: {
                translationKey: 'commons.works.categories.solar.title',
                choices: {
                    combinedSolarSystem: {
                        icon: '/assets/svg/combined-solar-system.svg',
                        translationKey: 'commons.works.categories.solar.choices.combinedSolarSystem',
                    },
                    photovoltaicSolarPanels: {
                        icon: '/assets/svg/photovoltaic-solar-panels.svg',
                        translationKey: 'commons.works.categories.solar.choices.photovoltaicSolarPanel',
                    },
                    aerovoltaticPanels: {
                        icon: '/assets/svg/aerovoltaic-panels.svg',
                        translationKey: 'commons.works.categories.solar.choices.aerovoltaticPanels',
                    },
                },
            },
            waterHeater: {
                choices: {
                    individualSolarWaterHeater: {
                        icon: '/assets/svg/individual-solar-water-heater.svg',
                        translationKey: 'commons.works.categories.waterHeater.choices.individualSolarWaterHeater',
                        requiredNextStep: 'SOLAR_PANELS_SURFACE_COMMON_STEP',
                    },
                },
            },
        },
    },
    APARTMENT_WORKS_COMMON_STEP
);

// Ex: 3.7.2
export const WALLS_ISOLATION_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.wallsIsolationTypes.title',
    type: 'choices',
    requiredNextStep: 'WALLS_ISOLATION_SURFACE_COMMON_STEP',
    choices: {
        interior: {
            icon: '/assets/svg/interior-isolation.svg',
            translationKey: 'commons.wallsIsolationTypes.choices.interior',
        },
        exterior: {
            icon: '/assets/svg/exterior-isolation.svg',
            translationKey: 'commons.wallsIsolationTypes.choices.exterior',
        },
        notKnown: {
            icon: '/assets/svg/interrogation.svg',
            translationKey: 'commons.wallsIsolationTypes.choices.notKnown',
        },
    },
});

// Ex: 3.7.2.1 | 4.9.2.1
export const WALLS_ISOLATION_SURFACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.wallsIsolationSurface.title',
    type: 'fields',
    name: 'surface_mur',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surface.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 3.7.3
export const BASEMENT_ISOLATION_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.basementIsolationTypes.title',
    name: 'basement_isolation_type',
    type: 'choices',
    requiredNextStep: 'BASEMENT_ISOLATION_SURFACE_COMMON_STEP',
    choices: {
        cave: {
            icon: '/assets/svg/cave.svg',
            translationKey: 'commons.basementIsolationTypes.choices.cave',
        },
        platform: {
            icon: '/assets/svg/platform.svg',
            translationKey: 'commons.basementIsolationTypes.choices.platform',
        },
        crawlspace: {
            icon: '/assets/svg/crawlspace.svg',
            translationKey: 'commons.basementIsolationTypes.choices.crawlspace',
        },
        garage: {
            icon: '/assets/svg/garage.svg',
            translationKey: 'commons.basementIsolationTypes.choices.garage',
        },
    },
});

// Ex: 3.7.4
export const WINDOWS_ISOLATION_COUNT_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.windowsIsolationCount.title',
    group: StepsGroups.project,
    name: 'numberOfWindows',
    type: 'fields',
    fields: SmallNumberField,
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 3.7.5
export const ROOF_ISOLATION_SURFACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.roofIsolationSurface.title',
    name: 'flatRoofIsolationSurface',
    type: 'fields',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surface.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 3.7.6.
export const HEAT_PUMP_TYPES_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.heatPumpTypes.title',
    type: 'choices',
    choices: {
        lowTemperature: {
            icon: '/assets/svg/low-temp.svg',
            translationKey: 'commons.heatPumpTypes.choices.lowTemperature',
        },
        mediumTemperature: {
            icon: '/assets/svg/medium-temp.svg',
            translationKey: 'commons.heatPumpTypes.choices.mediumTemperature',
        },
        highTemperature: {
            icon: '/assets/svg/high-temp.svg',
            translationKey: 'commons.heatPumpTypes.choices.highTemperature',
        },
        notKnown: {
            icon: '/assets/svg/interrogation.svg',
            translationKey: 'commons.heatPumpTypes.choices.notKnown',
        },
    },
});

// Ex: 3.7.8
export const SOLAR_PANELS_SURFACE_COMMON_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.solarPanelsSurface.title',
    type: 'fields',
    name: 'solarPanelsSurface',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.surfacePose.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

// Ex: 3.8 | 4.11
export const CURRENT_PROJECT_STEP_COMMON_STEP = Object.freeze({
    titleTranslationKey: 'commons.currentProjectStep.title',
    type: 'choices',
    choices: {
        thinking: {
            icon: '/assets/svg/thinking.svg',
            translationKey: 'commons.currentProjectStep.choices.thinking',
        },
        searchingForCraftsman: {
            icon: '/assets/svg/worker.svg',
            translationKey: 'commons.currentProjectStep.choices.searchingForCraftsman',
        },
        aboutToSignQuotation: {
            icon: '/assets/svg/quote.svg',
            translationKey: 'commons.currentProjectStep.choices.aboutToSignQuotation',
        },
        alreadySignedQuotation: {
            icon: '/assets/svg/signed-quote.svg',
            translationKey: 'commons.currentProjectStep.choices.alreadySignedQuotation',
        },
    },
});
export const POSTAL_CODE_COMMON_STEP = Object.freeze({
    group: StepsGroups.infos,
    titleTranslationKey: 'commons.worksPostalCode.title',
    type: 'fields',
    fields: PostalCodeFields,
    getValidationSchema: getPostalCodeSchema,
    getShouldButtonBeDisabled: (currentStepValues) => ['zipCode', 'city'].some((key) => !currentStepValues[key]),
});
// 2.2 | 4.2
export const WORKS_POSTAL_CODE_COMMON_STEP = Object.freeze({
    ...POSTAL_CODE_COMMON_STEP,
    titleTranslationKey: 'commons.worksPostalCode.title',
});

// Ex: 1.4 | 3.9
export const HOUSING_LOCATION_POSTAL_CODE_COMMON_STEP = Object.freeze({
    ...POSTAL_CODE_COMMON_STEP,
    titleTranslationKey: 'commons.housingLocationPostalCode.title',
});

// Ex: 3.10.1 | 4.13.1
export const PRINCIPAL_RESIDENCE_POSTAL_CODE_COMMON_STEP = Object.freeze({
    ...POSTAL_CODE_COMMON_STEP,
    titleTranslationKey: 'commons.principalResidencePostalCode.title',
});

export const SINCE_WHEN_HOME_BUILT = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: '4.3.title',
    type: 'choices',
    choices: {
        lessThanTwoYears: {
            icon: '/assets/svg/house.svg',
            translationKey: '4.3.choices.lessThanTwoYears',
        },
        betweenTwoAndFifteenYears: {
            icon: '/assets/svg/house.svg',
            translationKey: '4.3.choices.betweenTwoAndFifteenYears',
        },
        moreThanFifteenYears: {
            icon: '/assets/svg/house.svg',
            translationKey: '4.3.choices.moreThanFifteenYears',
        },
    },
});

export const CURRENT_HOUSING_ENERGY_COMMON_HOT_CHOICES_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.currentHousingEnergy.title',
    subTitleTranslationKey: 'commons.currentHousingEnergy.subTitle',
    type: 'choices',
    name: 'type_heating',
    choices: {
        woodHeating: {
            icon: '/assets/svg/electric.svg',
            translationKey: 'commons.works.categories.waterHeater.choices.electricWaterHeater',
        },
        gasHeating: {
            icon: '/assets/svg/gas.svg',
            translationKey: 'commons.currentHousingEnergy.choices.gasHeating',
        },
        thermodynamicWaterHeater: {
            icon: '/assets/svg/thermodynamic-water-boiler.svg',
            translationKey: 'commons.works.categories.waterHeater.choices.thermodynamicWaterHeater',
        },
        individualSolarWaterHeater: {
            icon: '/assets/svg/individual-solar-water-heater.svg',
            translationKey: 'commons.works.categories.waterHeater.choices.individualSolarWaterHeater',
        },
    },
});

export const HEIGHT_UNDER_CEILING_STEP = Object.freeze({
    group: StepsGroups.project,
    titleTranslationKey: 'commons.heightUnderCeiling.title',
    name: 'height_under_ceiling',
    type: 'fields',
    fields: SimpleField,
    props: {
        labelTranslationKey: 'fields.heightUnderCeiling.value.label',
        type: 'number',
    },
    getValidationSchema: getDefaultSchema('number', 0),
    getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
});

export const myFirstRecurrentWay = (index, title = null, plusProps = {}) =>
    Object.freeze({
        ...(title
            ? {
                  [title]: merge(
                      {
                          choices: {
                              house: { nextStep: `${index}.2` },
                              apartment: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                          },
                          ...plusProps,
                      },
                      HOUSING_TYPES_COMMON_STEP
                  ),
              }
            : {}),
        [`${index}.2`]: merge(
            {
                choices: {
                    landlord: { nextStep: `${index}.3` },
                    tenant: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                },
            },
            LANDLORD_OR_TENANT_COMMON_STEP
        ),
        [`${index}.3`]: merge(
            {
                choices: {
                    attics: {
                        nextStep: `${index}.3.1`,
                    },
                    garage: {
                        nextStep: `${index}.3.3`,
                    },
                    basement: {
                        nextStep: `${index}.4`,
                    },
                    crawlspace: {
                        nextStep: `${index}.3.1.2`,
                    },
                    interior: {
                        nextStep: `${index}.3.4`,
                    },
                },
            },
            ISOLATION_SELECTION_COMMON_STEP
        ),
        [`${index}.3.1.1`]: merge(
            {
                choices: {
                    lostAttics: {
                        nextStep: `${index}.3.2.1`,
                    },
                    convertedAttics: {
                        nextStep: `${index}.3.2`,
                    },
                    multipleAttics: {
                        nextStep: `${index}.3.2.1`,
                    },
                },
            },
            ATTICS_ISOLATION_TYPES_COMMON_STEP
        ),
        [`${index}.3.1.2`]: merge(
            {
                nextStep: `${index}.3.1.2.1`,
            },
            ACCESSIBLE_CRAWLSPACE_COMMON_STEP
        ),
        [`${index}.3.1.2.1`]: merge(
            {
                nextStep: `${index}.3.1.2.1.1`,
            },
            CRAWLSPACE_HEIGHT_COMMON_STEP
        ),
        [`${index}.3.1.2.1.1`]: merge(
            {
                nextStep: `${index}.4`,
            },
            CRAWLSPACE_TRAP_CONTENTS_COMMON_STEP
        ),
        [`${index}.3.1`]: merge(
            {
                choices: {
                    lostAttics: {
                        nextStep: `${index}.3.2.1`,
                    },
                    convertedAttics: {
                        nextStep: `${index}.3.2`,
                    },
                    multipleAttics: {
                        nextStep: `${index}.3.2.1`,
                    },
                },
            },
            ATTICS_ISOLATION_TYPES_COMMON_STEP
        ),
        [`${index}.3.2`]: {
            group: StepsGroups.project,
            titleTranslationKey: `commons.heightUnderCeiling.title`,
            name: 'height_under_ceiling',
            type: `fields`,
            fields: SimpleField,
            props: {
                labelTranslationKey: `fields.meterMeasure.value.label`,
            },
            getValidationSchema: getDefaultSchema(`number`, 0),
            getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
            nextStep: `${index}.4`,
        },
        [`${index}.3.2.1`]: merge({ nextStep: `${index}.3.2` }, ACCESSIBLE_ATTICS_LOSTED_COMMON_STEP),
        [`${index}.3.3`]: merge({ nextStep: `${index}.4` }, GARAGE_TYPES_COMMON_STEP),
        [`${index}.3.4`]: merge({ nextStep: `${index}.3.5` }, HOUSING_SURFACE_COMMON_STEP),
        [`${index}.3.5`]: merge({ nextStep: `${index}.5` }, HOW_MANY_LEVELS_HEAT_COMMON_STEP),
        [`${index}.4`]: {
            group: StepsGroups.project,
            titleTranslationKey: `5.4.title`,
            name: 'surface_habitable_chauffe',
            type: `fields`,
            fields: SimpleField,
            props: {
                labelTranslationKey: `fields.surface.value.label`,
            },
            getValidationSchema: getDefaultSchema(`number`, 0),
            getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
            nextStep: `${index}.5`,
        },
        [`${index}.5`]: merge({ nextStep: `${index}.6` }, CURRENT_HOUSING_ENERGY_COMMON_STEP),
        [`${index}.6`]: merge(
            {
                group: StepsGroups.project,
                nextStep: `${index}.7`,
            },
            WORKS_POSTAL_CODE_COMMON_STEP
        ),
        [`${index}.7`]: merge({ nextStep: `${index}.8` }, HOUSING_MEMBERS_COUNT_COMMON_STEP),
        [`${index}.8`]: merge({ nextStep: `${index}.9` }, TAX_INCOME_COMMON_STEP),
    });

export const mySecondRecurrentWay = (
    index,
    title,
    plusProps = {},
    actualHeatingChoices = ['oilHeating', 'electricHeating', 'coalHeating', 'woodHeating', 'heatPump', 'gasHeating']
) =>
    Object.freeze({
        ...(title
            ? {
                  [title]: merge(
                      {
                          choices: actualHeatingChoices.reduce(
                              (obj, choice) => ({
                                  [choice]: {
                                      oilHeating: { nextStep: `${index}.2.1` },
                                      electricHeating: { nextStep: `${index}.2` },
                                      coalHeating: { nextStep: `${index}.2` },
                                      woodHeating: { nextStep: `${index}.2` },
                                      heatPump: { nextStep: `${index}.2` },
                                      gasHeating: { nextStep: `${index}.2.1` },
                                  }[choice],
                                  ...obj,
                              }),
                              {}
                          ),
                          ...plusProps,
                      },
                      {
                          ...CURRENT_HOUSING_ENERGY_COMMON_STEP,
                          choices: actualHeatingChoices.reduce(
                              (obj, choice) => ({
                                  ...obj,
                                  [choice]: CURRENT_HOUSING_ENERGY_COMMON_STEP.choices[choice],
                              }),
                              {}
                          ),
                          ...(plusProps.subTitleTranslationKey
                              ? { subTitleTranslationKey: plusProps.subTitleTranslationKey }
                              : {}),
                      }
                  ),
              }
            : {}),
        [`${index}.2`]: merge(
            {
                choices: {
                    landlord: { nextStep: `${index}.3` },
                    tenant: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                },
            },
            LANDLORD_OR_TENANT_COMMON_STEP
        ),
        [`${index}.2.1`]: merge(
            { choices: { yes: { nextStep: `${index}.2.2` }, no: { nextStep: `${index}.2` } } },
            CONDENSING_GAZ_BOILER_COMMON_STEP
        ),
        [`${index}.2.2`]: merge(
            {
                nextStep: (stepValues) =>
                    new Date().getFullYear() - Number((stepValues[`${index}.2.2`] || {}).value) > 17
                        ? `${index}.2`
                        : routesTitles.GENERAL_INEGIBILITY,
            },
            CONDENSING_GAZ_BOILER_DATE_COMMON_STEP
        ),
        [`${index}.3`]: merge(
            {
                choices: {
                    house: { nextStep: `${index}.4` },
                    apartment: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                },
            },
            HOUSING_TYPES_COMMON_STEP
        ),
        [`${index}.4`]: merge(
            {
                choices: {
                    yes: { nextStep: `${index}.4.1` },
                    no: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                },
            },
            IS_OLDER_THAN_TWO_YEARS_COMMON_STEP
        ),
        [`${index}.4.1`]: merge(
            {
                choices: {
                    yes: { nextStep: routesTitles.GENERAL_INEGIBILITY },
                    no: { nextStep: `${index}.5` },
                },
            },
            ISOLATION_ONE_EURO_ALREADY_DONE_COMMON_STEP
        ),
        [`${index}.5`]: merge({ nextStep: `${index}.6` }, HOUSING_SURFACE_COMMON_STEP),
        [`${index}.6`]: merge({ nextStep: `${index}.7` }, WORKS_POSTAL_CODE_COMMON_STEP),
        [`${index}.7`]: merge({ nextStep: `${index}.8` }, HOUSING_MEMBERS_COUNT_COMMON_STEP),
        [`${index}.8`]: merge({ nextStep: `${index}.9` }, TAX_INCOME_COMMON_STEP),
    });
