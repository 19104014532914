import * as React from 'react';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Button } from '../../../../designSystem/Button/Button';
import { Typography } from '../../../../designSystem/Typography/Typography';
import classes from './InstallHeatingFromPartners.module.sass';
import cn from 'classnames';

export const InstallHeatingFromPartners = () => {
    const { language, isSmall } = React.useContext(ApplicationContext);
    return (
        <div className={cn(classes.root, isSmall && classes.small)}>
            <div className={classes.illustrationTextsContainer}>
                <img
                    className={classes.illustration}
                    src="/assets/svg/install-heating-from-partners.svg"
                    alt={language.steps['contents.installHeatingFromPartners.subTitle']}
                />
                <div className={classes.texts}>
                    <Typography classes={{ root: classes.title }} component="h4" variant="title">
                        {language.steps['contents.installHeatingFromPartners.title.part.0']}
                        <span className={classes.primary}>
                            {language.steps['contents.installHeatingFromPartners.title.part.1']}
                        </span>
                        {language.steps['contents.installHeatingFromPartners.title.part.2']}
                        <span className={classes.secondary}>
                            {language.steps['contents.installHeatingFromPartners.title.part.3']}
                        </span>
                        {language.steps['contents.installHeatingFromPartners.title.part.4']}
                    </Typography>
                    <Typography classes={{ root: classes.subTitle }} variant="subTitle1">
                        {language.steps['contents.installHeatingFromPartners.subTitle']}
                    </Typography>
                    <Typography classes={{ root: classes.smallText }}>
                        {language.steps['contents.installHeatingFromPartners.smallText']}
                    </Typography>
                </div>
            </div>
            <Button>{language.steps['contents.installHeatingFromPartners.button']}</Button>
        </div>
    );
};
