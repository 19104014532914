import { StepsGroups } from '../components/routesComponents/Steps/Groups/Groups.data';

export const FR = {
    [StepsGroups.needs]: 'Vos besoins',
    [StepsGroups.project]: 'Votre projet',
    [StepsGroups.infos]: 'Vos informations',
    [StepsGroups.eligibility]: 'Votre éligibilité',
};

export const EN = {
    [StepsGroups.needs]: 'Vos besoins',
    [StepsGroups.project]: 'Votre projet',
    [StepsGroups.infos]: 'Vos informations',
    [StepsGroups.eligibility]: 'Votre éligibilité',
};

export const groupLanguage = {
    fr: FR,
    us: EN,
};
