import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as ArrowIcon } from '../../../../../assets/svg/arrow.svg';
import { useCallbackOpen } from '../../../../../hooks/useCallbackOpen';
import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Typography } from '../../../../designSystem/Typography/Typography';
import { StepsUtilsContext } from '../../Steps.context';
import classes from './GoBackButton.module.sass';

export const GoBackButton = ({ classes: receivedClasses = {} }) => {
    const { language } = React.useContext(ApplicationContext);
    const { goToPreviousStep } = React.useContext(StepsUtilsContext);

    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();

    const arrowIconStyles = useSpring({
        translateX: isHovering ? -4 : 0,
    });

    return (
        <button
            type="button"
            onClick={goToPreviousStep}
            className={cn(classes.root, receivedClasses.root)}
            onFocus={setIsHovering}
            onBlur={setIsNotHovering}
            onMouseEnter={setIsHovering}
            onMouseLeave={setIsNotHovering}
        >
            <animated.span
                className={classes.iconContainer}
                style={{ transform: arrowIconStyles.translateX.to((value) => `translate3d(${value}px, 0, 0)`) }}
            >
                <ArrowIcon className={classes.icon} />
            </animated.span>
            <Typography variant="subTitle3" classes={{ root: classes.typography }}>
                {language.commons.goBack}
            </Typography>
        </button>
    );
};
