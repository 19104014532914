import cn from 'classnames';
import { isEmpty, isObject } from 'lodash';
import * as React from 'react';

import { extractChoicesFromCategories, getColor, colors } from '../../../../../helpers';
import { useStepsUtils } from '../../../../../hooks/useStepsUtils';
import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Button } from '../../../../designSystem/Button/Button';
import { Typography } from '../../../../designSystem/Typography/Typography';
import { STEPS } from '../../Steps.data';
import classes from '../IsolationEligibility/IsolationEligibility.module.sass';
import { getPrimes } from './helpers';

export const CalculAids = () => {
    const { language, isSmall } = React.useContext(ApplicationContext);
    const { values } = useStepsUtils();

    const choicesArr = {};
    const body = Object.entries(values).reduce((acc, [stepId, stepValues]) => {
        if (!stepValues) {
            return { ...acc };
        }
        if (STEPS[stepId].type === 'choices') {
            const choices = STEPS[stepId].categories
                ? extractChoicesFromCategories(STEPS[stepId].categories)
                : STEPS[stepId].choices;

            choicesArr[STEPS[stepId].name || 'value'] = (
                Array.isArray(stepValues.value)
                    ? stepValues.value.map((value) => choices[value])
                    : [choices[stepValues.value]]
            )
                .map((value) => language[value.translationKey])
                .join(', ');
        }
        if (stepValues.value) {
            return {
                ...acc,
                [STEPS[stepId].name || 'value']: stepValues.value,
            };
        }
        return {
            ...acc,
            ...stepValues,
        };
    }, {});

    const [primes, setPrimes] = React.useState('Calcul en cours');
    React.useEffect(() => {
        if (body && !isEmpty(body) && isObject(body) && body.zipCode && body.taxIncomeNumber) {
            const color = getColor(body);
            const { worksWishCategories } = body;
            const primesCalculated = getPrimes(worksWishCategories, colors.indexOf(color), body);
            setPrimes(primesCalculated);
        }
    }, [body]);

    return (
        <div className={cn(classes.root, isSmall && classes.small)}>
            <div className={classes.textsIllustrationContainer}>
                <div className={classes.texts}>
                    <Typography classes={{ root: classes.title }} variant="title">
                        {language.steps['contents.eligibilityAids.title.1']}
                        <span className={classes.boldenLittleMargin}>{primes}</span>
                        {language.steps['contents.eligibilityAids.title.2']}
                    </Typography>
                    <div className={classes.listSmallText}>
                        <ul className={classes.list}>
                            <li>{language.steps['commons.MPREligibility.list.1']}</li>
                            <li>{language.steps['commons.MPREligibility.list.2']}</li>
                        </ul>
                        <Typography classes={{ root: classes.smallText }}>
                            {language.steps['contents.isolationEligibility.smallText']}
                        </Typography>
                    </div>
                </div>
                <img
                    className={classes.illustration}
                    src="/assets/svg/lets-talk.svg"
                    alt={language.steps['contents.isolationEligibility.title']}
                />
            </div>
            <Button classes={{ root: classes.button }}>
                {language.steps['contents.isolationEligibility.button'].replace('XX XX XX XX XX', body.phones)}
            </Button>
        </div>
    );
};
