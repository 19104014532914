import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../commons/Application/Application.context';
import { GroupItem } from './GroupItem/GroupItem';
import { StepsGroups } from './Groups.data';
import classes from './Groups.module.sass';

export const Groups = ({ classes: receivedClasses = {} }) => {
    const { isSmall } = React.useContext(ApplicationContext);
    const groupsKeys = React.useMemo(() => Object.keys(StepsGroups), []);
    return (
        <div className={cn(classes.root, isSmall && classes.small, receivedClasses.root)}>
            {groupsKeys.map((groupId) => (
                <GroupItem key={`group_${groupId}`} classes={{ root: classes.item }} {...{ groupId, isSmall }} />
            ))}
        </div>
    );
};
