import { primary, secondary } from '../../../../../utils/variables';

const defaultColor = '#E5E5E5';

export const getGroupItemBackgroundColor = (isPast, isCurrent) => {
    if (isPast) {
        return secondary;
    }
    if (isCurrent) {
        return primary;
    }
    return defaultColor;
};

export const getCircleSpringProps = (isPast, isCurrent) => ({
    backgroundColor: getGroupItemBackgroundColor(isPast, isCurrent),
});
