import { secondary } from '../../../utils/variables';

export const CHOICE_ITEM_SPRING_PROPS = Object.freeze({
    default: {
        boxShadow: '0 0 10px #dadada',
        color: '#111',
        borderColor: `rgba(0, 0, 0, 0)`,
    },
    hovering: {
        boxShadow: `0 0 10px ${secondary}`,
        color: '#111',
        borderColor: `rgba(0, 0, 0, 0)`,
    },
    selected: {
        boxShadow: `0 0 10px ${secondary}`,
        color: secondary,
        borderColor: secondary,
    },
});

export const SELECTED_OVERLAY_TRANSITIONS = Object.freeze({
    from: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    leave: {
        opacity: 0,
    },
});
