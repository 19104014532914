import cn from 'classnames';
import * as React from 'react';

import { useStepsUtils } from '../../../hooks/useStepsUtils';
import { ApplicationContext } from '../../commons/Application/Application.context';
import { Header } from '../../commons/Header/Header';
import Loader from '../Loader/Loader';
import { WithTransitionsCurrentStep } from './CurrentStep/CurrentStep';
import { SidePanel } from './SidePanel/SidePanel';
import { StepHeader } from './StepHeader/StepHeader';
import { StepsUtilsContext } from './Steps.context';
import classes from './Steps.module.sass';
import { StepsProgressBar } from './StepsProgressBar/StepsProgressBar';

export const Steps = () => {
    const { isSmall } = React.useContext(ApplicationContext);
    const stepsUtils = useStepsUtils();

    // Avoid useless re-renders.
    const stepsUtilsContextValues = React.useMemo(() => stepsUtils, [stepsUtils]);

    if (!stepsUtils.currentStep) {
        return null;
    }

    return (
        <StepsUtilsContext.Provider value={stepsUtilsContextValues}>
            {!isSmall && <Header />}
            <div className={cn(classes.root, isSmall && classes.small)}>
                {!isSmall && <SidePanel />}
                <div className={classes.step}>
                    <StepHeader classes={{ root: classes.stepHeader }} />
                    {stepsUtils.loader ? <Loader /> : <WithTransitionsCurrentStep />}
                    {isSmall && (
                        <div className={classes.smallStepsProgressBar}>
                            <StepsProgressBar />
                        </div>
                    )}
                </div>
            </div>
        </StepsUtilsContext.Provider>
    );
};
