import { merge } from 'lodash';

import { pushDatas } from '../../../../helpers';
import { urlEndGeneral } from '../../../../utils/variables';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import {
    APARTMENT_WORKS_COMMON_STEP,
    ATTICS_ISOLATION_SURFACE_COMMON_STEP,
    ATTICS_ISOLATION_TYPES_COMMON_STEP,
    BASEMENT_ISOLATION_SURFACE_COMMON_STEP,
    BASEMENT_ISOLATION_TYPES_COMMON_STEP,
    CURRENT_HOUSING_ENERGY_COMMON_STEP,
    CURRENT_PROJECT_STEP_COMMON_STEP,
    ELECTRIC_EQUIPMENTS_COMMON_STEP,
    GAS_EQUIPMENTS_COMMON_STEP,
    HEAT_PUMP_TYPES_COMMON_STEP,
    HOUSE_WORKS_COMMON_STEP,
    HOUSING_LOCATION_POSTAL_CODE_COMMON_STEP,
    HOUSING_MEMBERS_COUNT_COMMON_STEP,
    HOUSING_SURFACE_COMMON_STEP,
    HOUSING_TYPES_COMMON_STEP,
    IS_OLDER_THAN_TWO_YEARS_COMMON_STEP,
    NOT_ELIGIBLE_FOR_HEATING_OFFERS_COMMON_STEP,
    OIL_EQUIPMENTS_COMMON_STEP,
    PERSONAL_INFOS_COMMON_STEP,
    PRINCIPAL_RESIDENCE_POSTAL_CODE_COMMON_STEP,
    RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP,
    ROOF_ISOLATION_SURFACE_COMMON_STEP,
    SOLAR_PANELS_SURFACE_COMMON_STEP,
    TAX_INCOME_COMMON_STEP,
    WALLS_ISOLATION_SURFACE_COMMON_STEP,
    WALLS_ISOLATION_TYPES_COMMON_STEP,
    WINDOWS_ISOLATION_COUNT_COMMON_STEP,
} from './commonsSteps';

// 3.*.*

export const APPLY_FOR_BONUS_STEPS = Object.freeze({
    [routesTitles.APPLY_FOR_BONUS]: merge(
        {
            group: StepsGroups.project,
            choices: {
                house: {
                    nextStep: '3.2',
                },
                apartment: {
                    nextStep: routesTitles.GENERAL_INEGIBILITY,
                },
            },
        },
        HOUSING_TYPES_COMMON_STEP
    ),
    3.2: merge(
        {
            group: StepsGroups.project,
            choices: {
                yes: {
                    nextStep: '3.3',
                },
                no: {
                    nextStep: routesTitles.GENERAL_INEGIBILITY,
                },
            },
        },
        IS_OLDER_THAN_TWO_YEARS_COMMON_STEP
    ),
    '3.2.1': merge(
        {
            group: StepsGroups.eligibility,
        },
        NOT_ELIGIBLE_FOR_HEATING_OFFERS_COMMON_STEP
    ),
    3.3: merge(
        {
            group: StepsGroups.project,
            nextStep: '3.4',
        },
        HOUSING_SURFACE_COMMON_STEP
    ),
    3.4: merge(
        {
            group: StepsGroups.project,
            choices: {
                oilHeating: {
                    nextStep: '3.5.1',
                },
                electricHeating: {
                    nextStep: '3.5.2',
                },
                coalHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                woodHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                heatPump: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                gasHeating: {
                    nextStep: '3.5.3',
                },
            },
        },
        CURRENT_HOUSING_ENERGY_COMMON_STEP
    ),
    '3.5.1': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                otherThanCondensingHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                stove: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
            },
        },
        OIL_EQUIPMENTS_COMMON_STEP
    ),
    '3.5.2': merge(
        {
            group: StepsGroups.project,
            choices: {
                electricHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                heatedCeilingsOrFloors: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                electricHeater: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
            },
        },
        ELECTRIC_EQUIPMENTS_COMMON_STEP
    ),
    '3.5.3': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                condensingHeating2: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                gasRadiator: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
                gasStove: {
                    nextStep: (stepValues) =>
                        !stepValues[routesTitles.APPLY_FOR_BONUS] ||
                        stepValues[routesTitles.APPLY_FOR_BONUS].value === 'house'
                            ? '3.6.1'
                            : '3.6.2',
                },
            },
        },
        GAS_EQUIPMENTS_COMMON_STEP
    ),
    '3.6.1': merge(
        {
            group: StepsGroups.project,
            categories: {
                isolationVentilation: {
                    choices: {
                        attics: {
                            nextStep: '3.7.1',
                        },
                        walls: {
                            nextStep: '3.7.2',
                        },
                        floors: {
                            nextStep: '3.7.3',
                        },
                        windows: {
                            nextStep: '3.7.4',
                        },
                        flatRoof: {
                            nextStep: '3.7.5',
                        },
                    },
                },
                woodHeating: {
                    choices: {
                        fireplaceInsert: {
                            nextStep: '3.8',
                        },
                        woodStove: {
                            nextStep: '3.8',
                        },
                        woodBoiler: {
                            nextStep: '3.8',
                        },
                    },
                },
                solar: {
                    choices: {
                        combinedSolarSystem: {
                            nextStep: '3.8',
                        },
                        photovoltaicSolarPanels: {
                            nextStep: '3.8',
                        },
                        aerovoltaticPanels: {
                            nextStep: '3.8',
                        },
                    },
                },
                heatPump: {
                    choices: {
                        airWaterHeatPump: {
                            nextStep: '3.7.6',
                        },
                        geothermalHeatPump: {
                            nextStep: '3.7.6',
                        },
                        airAirHeatPump: {
                            nextStep: '3.7.6',
                        },
                        hybridHeatPump: {
                            nextStep: '3.7.6',
                        },
                    },
                },
                traditionalHeating: {
                    choices: {
                        oilCondensingBoiler: {
                            nextStep: '3.8',
                        },
                        gasCondensingBoiler: {
                            nextStep: '3.8',
                        },
                        inertiaRadiator: {
                            nextStep: '3.7.7',
                        },
                    },
                },
                waterHeater: {
                    choices: {
                        individualSolarWaterHeater: {
                            nextStep: '3.7.8',
                        },
                        thermodynamicWaterHeater: {
                            nextStep: '3.8',
                        },
                    },
                },
            },
        },
        HOUSE_WORKS_COMMON_STEP
    ),
    '3.6.2': merge(
        {
            group: StepsGroups.project,
            categories: {
                isolationVentilation: {
                    choices: {
                        walls: {
                            nextStep: '3.7.2',
                        },
                        windows: {
                            nextStep: '3.7.4',
                        },
                        flatRoof: {
                            nextStep: '3.7.5',
                        },
                    },
                },
                heatPump: {
                    choices: {
                        airWaterHeatPump: {
                            nextStep: '3.7.6',
                        },
                        geothermalHeatPump: {
                            nextStep: '3.7.6',
                        },
                        airAirHeatPump: {
                            nextStep: '3.8',
                        },
                        hybridHeatPump: {
                            nextStep: '3.7.6',
                        },
                    },
                },
                traditionalHeating: {
                    choices: {
                        oilCondensingBoiler: {
                            nextStep: '3.8',
                        },
                        gasCondensingBoiler: {
                            nextStep: '3.8',
                        },
                        inertiaRadiator: {
                            nextStep: '3.7.7',
                        },
                    },
                },
                waterHeater: {
                    choices: {
                        thermodynamicWaterHeater: {
                            nextStep: '3.8',
                        },
                    },
                },
            },
        },
        APARTMENT_WORKS_COMMON_STEP
    ),
    '3.7.1': merge(
        {
            group: StepsGroups.project,
            choices: {
                lostAttics: {
                    nextStep: '3.7.1.1',
                },
                convertedAttics: {
                    nextStep: '3.7.1.1',
                },
            },
        },
        ATTICS_ISOLATION_TYPES_COMMON_STEP
    ),
    '3.7.1.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        ATTICS_ISOLATION_SURFACE_COMMON_STEP
    ),
    '3.7.2': merge(
        {
            group: StepsGroups.project,
            choices: {
                interior: {
                    nextStep: '3.7.2.1',
                },
                exterior: {
                    nextStep: '3.7.2.1',
                },
                notKnown: {
                    nextStep: '3.7.2.1',
                },
            },
        },
        WALLS_ISOLATION_TYPES_COMMON_STEP
    ),
    '3.7.2.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        WALLS_ISOLATION_SURFACE_COMMON_STEP
    ),
    '3.7.3': merge(
        {
            group: StepsGroups.project,
            choices: {
                cave: {
                    nextStep: '3.7.3.1',
                },
                platform: {
                    nextStep: '3.7.3.1',
                },
                crawlspace: {
                    nextStep: '3.7.3.1',
                },
                garage: {
                    nextStep: '3.7.3.1',
                },
            },
        },
        BASEMENT_ISOLATION_TYPES_COMMON_STEP
    ),
    '3.7.3.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        BASEMENT_ISOLATION_SURFACE_COMMON_STEP
    ),
    '3.7.4': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        WINDOWS_ISOLATION_COUNT_COMMON_STEP
    ),
    '3.7.5': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        ROOF_ISOLATION_SURFACE_COMMON_STEP
    ),
    '3.7.6': merge(
        {
            group: StepsGroups.project,
            choices: {
                lowTemperature: {
                    nextStep: '3.8',
                },
                mediumTemperature: {
                    nextStep: '3.8',
                },
                highTemperature: {
                    nextStep: '3.8',
                },
                notKnown: {
                    nextStep: '3.8',
                },
            },
        },
        HEAT_PUMP_TYPES_COMMON_STEP
    ),
    '3.7.7': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP
    ),
    '3.7.8': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.8',
        },
        SOLAR_PANELS_SURFACE_COMMON_STEP
    ),
    3.8: merge(
        {
            group: StepsGroups.project,
            choices: {
                thinking: {
                    nextStep: '3.9',
                },
                searchingForCraftsman: {
                    nextStep: '3.9',
                },
                aboutToSignQuotation: {
                    nextStep: '3.9',
                },
                alreadySignedQuotation: {
                    nextStep: '3.9',
                },
            },
        },
        CURRENT_PROJECT_STEP_COMMON_STEP
    ),
    3.9: merge(
        {
            group: StepsGroups.project,
            nextStep: '3.10',
        },
        HOUSING_LOCATION_POSTAL_CODE_COMMON_STEP
    ),
    '3.10': {
        group: StepsGroups.project,
        titleTranslationKey: '3.10.title',
        type: 'choices',
        choices: {
            yes: {
                icon: '/assets/svg/yes.svg',
                translationKey: '3.10.choices.yes',
                nextStep: '3.11',
            },
            no: {
                icon: '/assets/svg/no.svg',
                translationKey: '3.10.choices.no',
                nextStep: '3.10.1',
            },
        },
    },
    '3.10.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '3.11',
        },
        PRINCIPAL_RESIDENCE_POSTAL_CODE_COMMON_STEP
    ),
    3.11: merge(
        {
            group: StepsGroups.infos,
            nextStep: '3.12',
        },
        HOUSING_MEMBERS_COUNT_COMMON_STEP
    ),
    3.12: merge(
        {
            group: StepsGroups.infos,
            choices: {
                lessThanX: {
                    nextStep: '3.13',
                },
                betweenXAndY: {
                    nextStep: '3.13',
                },
                betweenYAndZ: {
                    nextStep: '3.13',
                },
                moreThanZ: {
                    nextStep: '3.13',
                },
            },
        },
        TAX_INCOME_COMMON_STEP
    ),
    3.13: merge(
        {
            group: StepsGroups.infos,
            // nextStep: '3.14',
            runFunction: pushDatas,
            replace: urlEndGeneral,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
    // 3.14: merge(
    //     {
    //         group: StepsGroups.eligibility,
    //         runFunction: pushDatas,
    //     },
    //     ELIGIBLE_FOR_BONUS_COMMON_STEP
    // ),
});
