import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { PERSONAL_INFOS_COMMON_STEP, mySecondRecurrentWay, ENERGY_HOME_REFRESH_TYPE_COMMON_STEP } from './commonsSteps';

// 8.*.*
export const REFRESH_HOME_STEPS = Object.freeze({
    [routesTitles.REFRESH_HOME]: merge({ nextStep: `7.2`, title: 'refreshHome' }, ENERGY_HOME_REFRESH_TYPE_COMMON_STEP),
    ...mySecondRecurrentWay(8),
    8.9: merge(
        {
            group: StepsGroups.infos,
            replace: redirectUrlDefault,
            runFunction: pushDatas,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
