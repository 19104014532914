export const FR = {
    headerTagLine: 'Isolation à partir de 1 € : Testez votre éligibilité !*',
    goBack: 'Retour',
};

export const EN = {
    headerTagLine: 'Isolation from 1€, test your eligibility!*',
    goBack: 'Go back',
};

export const commonsLanguage = {
    fr: FR,
    us: EN,
};
