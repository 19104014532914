import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../commons/Application/Application.context';
import { Typography } from '../../../designSystem/Typography/Typography';
import classes from './StepTitles.module.sass';

export const StepTitles = ({
    titleTranslationKey,
    subTitleTranslationKey,
    miniSubTitle,
    classes: receivedClasses = {},
    coloredLastTitle = null,
    // = {
    //     title, color
    // }
}) => {
    const { language, isSmall } = React.useContext(ApplicationContext);
    return (
        <div className={cn(classes.root, isSmall && classes.small, receivedClasses.root)}>
            <Typography variant="title" component="h2" classes={{ root: classes.title }}>
                {language.steps[titleTranslationKey]}
                {coloredLastTitle && (
                    <span style={{ color: coloredLastTitle.color }} className={classes.boldenLittleMargin}>
                        {language.steps[coloredLastTitle.title]}
                    </span>
                )}
            </Typography>
            {Boolean(subTitleTranslationKey) && (
                <Typography
                    variant="subTitle1"
                    component="h3"
                    classes={{ root: cn(classes.subTitle, miniSubTitle && isSmall && classes.mini) }}
                >
                    <div dangerouslySetInnerHTML={{ __html: language.steps[subTitleTranslationKey] }} />
                </Typography>
            )}
        </div>
    );
};
