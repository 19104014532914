import * as yup from 'yup';

export const getDefaultSchema =
    (type = 'string', min, max) =>
    (translations) => {
        let value;
        if (type === 'string') {
            value = yup.string.typeError(translations.string);
        } else {
            value = yup.number().typeError(translations.number);
        }
        if (min) {
            value = value.min(min, translations.min(min));
        }
        if (max) {
            value = value.max(max, translations.max(max));
        }
        value = value.required(translations.required);

        return yup.object().shape({
            value,
        });
    };
