import React from 'react';

import { Typography } from '../../designSystem/Typography/Typography';

const CookieWarning = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
            padding: '20px',
        }}
    >
        <Typography style={{ fontSize: '20px' }}>
            {
                'Le formulaire de contact fonctionne avec les cookies, veuillez activer les cookies puis recharger la page pour y accéder.'
            }
        </Typography>
    </div>
);

export default CookieWarning;
