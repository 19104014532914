import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { PERSONAL_INFOS_COMMON_STEP, CURRENT_HOUSING_ENERGY_COMMON_STEP, mySecondRecurrentWay } from './commonsSteps';

// 7.*.*

export const HEAT_HOME_WATER_STEPS = Object.freeze({
    [routesTitles.HEAT_HOME_WATER]: merge(
        {
            choices: {
                oilHeating: { nextStep: `7.2.1` },
                electricHeating: { nextStep: `7.2` },
                coalHeating: { nextStep: `7.2` },
                woodHeating: { nextStep: `7.2` },
                heatPump: { nextStep: `7.2` },
                gasHeating: { nextStep: `7.2.1` },
            },
        },
        {
            ...CURRENT_HOUSING_ENERGY_COMMON_STEP,
            titleTranslationKey: 'commons.currentHousingEnergyHotWater.title',
            title: 'heatHomeWater',
        }
    ),
    ...mySecondRecurrentWay(7),
    7.9: merge(
        {
            group: StepsGroups.infos,
            replace: redirectUrlDefault,
            runFunction: pushDatas,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
