const apiKey = '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'
const listId = 7; // Number | Id of the list

export const createContact = async (email, options) => fetch('https://api.sendinblue.com/v3/contacts', {
			...options, body: JSON.stringify({
				email,
				updateEnabled: false
			})
		}).catch(e => console.log(e))

export const addExistingContactToList = async (email, options) => fetch(
			`https://api.sendinblue.com/v3/contacts/lists/${listId}/contacts/add`,
			{
				...options,
				body: JSON.stringify({ emails: [email] })
			}).catch ((e)=> {
		console.log(e)
	})

export const addLeadToSibList = async (email) => {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'api-key': apiKey
		},
	};
	try {
		await createContact(email, options)
		await addExistingContactToList(email, options)
	} catch (e) {
		console.log(e)
	}
};



export default {
	addLeadToSibList,
};
