import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import {
    CURRENT_HOUSING_ENERGY_COMMON_STEP,
    HOUSING_MEMBERS_COUNT_COMMON_STEP,
    HOUSING_TYPES_COMMON_STEP,
    LANDLORD_OR_TENANT_COMMON_STEP,
    PERSONAL_INFOS_COMMON_STEP,
    TAX_INCOME_COMMON_STEP,
    WORKS_POSTAL_CODE_COMMON_STEP,
    HOUSING_SURFACE_COMMON_STEP,
} from './commonsSteps';

// 13.*.*
export const PAC_STEPS = Object.freeze({
    [routesTitles.PAC]: merge(
        {
            choices: {
                landlord: { nextStep: '13.2' },
                tenant: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        { ...LANDLORD_OR_TENANT_COMMON_STEP, title: 'heatHome' }
    ),
    13.2: merge(
        {
            choices: {
                house: { nextStep: '13.3' },
                apartment: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        HOUSING_TYPES_COMMON_STEP
    ),
    13.3: merge({ nextStep: '13.4' }, CURRENT_HOUSING_ENERGY_COMMON_STEP),
    13.4: merge({ nextStep: '13.5' }, HOUSING_SURFACE_COMMON_STEP),
    13.5: merge({ nextStep: '13.6' }, WORKS_POSTAL_CODE_COMMON_STEP),
    13.6: merge({ nextStep: '13.7' }, HOUSING_MEMBERS_COUNT_COMMON_STEP),
    13.7: merge({ nextStep: '13.8' }, TAX_INCOME_COMMON_STEP),
    13.8: merge(
        {
            group: StepsGroups.infos,
            replace: redirectUrlDefault,
            runFunction: pushDatas,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
