// 4.*.*

import { merge } from 'lodash';

import { pushDatas } from '../../../../helpers';
import { urlEndGeneral } from '../../../../utils/variables';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { EstimatedBudgetFields } from '../fields/EstimatedBudgetFields/EstimatedBudget';
import { HeatingBillFields } from '../fields/HeatingBillFields/HeatingBillFields';
import { getDefaultSchema } from '../schemas/default.schema';
import { getHeatingBillSchema } from '../schemas/heatingBill.schema';
import {
    APARTMENT_WORKS_COMMON_STEP,
    ATTICS_ISOLATION_SURFACE_COMMON_STEP,
    ATTICS_ISOLATION_TYPES_COMMON_STEP,
    BASEMENT_ISOLATION_SURFACE_COMMON_STEP,
    BASEMENT_ISOLATION_TYPES_COMMON_STEP,
    CURRENT_HOUSING_ENERGY_COMMON_STEP,
    CURRENT_PROJECT_STEP_COMMON_STEP,
    ELECTRIC_EQUIPMENTS_COMMON_STEP,
    GAS_EQUIPMENTS_COMMON_STEP,
    HEAT_PUMP_TYPES_COMMON_STEP,
    HOUSE_WORKS_COMMON_STEP,
    HOUSING_MEMBERS_COUNT_COMMON_STEP,
    HOUSING_SURFACE_COMMON_STEP,
    HOUSING_TYPES_COMMON_STEP,
    OIL_EQUIPMENTS_COMMON_STEP,
    PERSONAL_INFOS_COMMON_STEP,
    PRINCIPAL_RESIDENCE_POSTAL_CODE_COMMON_STEP,
    RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP,
    ROOF_ISOLATION_SURFACE_COMMON_STEP,
    SOLAR_PANELS_SURFACE_COMMON_STEP,
    WALLS_ISOLATION_SURFACE_COMMON_STEP,
    WALLS_ISOLATION_TYPES_COMMON_STEP,
    WINDOWS_ISOLATION_COUNT_COMMON_STEP,
    WORKS_POSTAL_CODE_COMMON_STEP,
    SINCE_WHEN_HOME_BUILT,
    TAX_INCOME_NUMBER_COMMON_STEP,
} from './commonsSteps';

export const CALCULATE_AIDS_STEPS = Object.freeze({
    [routesTitles.CALCULATE_AIDS]: merge(
        {
            group: StepsGroups.project,
            choices: {
                house: {
                    nextStep: '4.2',
                },
                apartment: {
                    nextStep: routesTitles.GENERAL_INEGIBILITY,
                },
            },
        },
        HOUSING_TYPES_COMMON_STEP
    ),
    4.2: merge(
        {
            group: StepsGroups.project,
            nextStep: '4.3',
        },
        WORKS_POSTAL_CODE_COMMON_STEP
    ),
    4.3: merge(
        {
            choices: {
                lessThanTwoYears: {
                    nextStep: '4.4',
                },
                betweenTwoAndFifteenYears: {
                    nextStep: '4.4',
                },
                moreThanFifteenYears: {
                    nextStep: '4.4',
                },
            },
        },
        SINCE_WHEN_HOME_BUILT
    ),
    4.4: merge(
        {
            group: StepsGroups.project,
            nextStep: 4.5,
        },
        HOUSING_SURFACE_COMMON_STEP
    ),
    4.5: merge(
        {
            group: StepsGroups.project,
            choices: {
                oilHeating: {
                    nextStep: '4.6.1',
                },
                electricHeating: {
                    nextStep: '4.6.2',
                },
                coalHeating: {
                    nextStep: '4.7',
                },
                woodHeating: {
                    nextStep: '4.7',
                },
                heatPump: {
                    nextStep: '4.7',
                },
                gasHeating: {
                    nextStep: '4.6.3',
                },
            },
        },
        CURRENT_HOUSING_ENERGY_COMMON_STEP
    ),
    '4.6.1': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: '4.7',
                },
                otherThanCondensingHeating: {
                    nextStep: '4.7',
                },
                stove: {
                    nextStep: '4.7',
                },
            },
        },
        OIL_EQUIPMENTS_COMMON_STEP
    ),
    '4.6.2': merge(
        {
            group: StepsGroups.project,
            choices: {
                electricHeating: {
                    nextStep: '4.7',
                },
                heatedCeilingsOrFloors: {
                    nextStep: '4.7',
                },
                electricHeater: {
                    nextStep: '4.7',
                },
            },
        },
        ELECTRIC_EQUIPMENTS_COMMON_STEP
    ),
    '4.6.3': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: '4.7',
                },
                condensingHeating2: {
                    nextStep: '4.7',
                },
                gasRadiator: {
                    nextStep: '4.7',
                },
                gasStove: {
                    nextStep: '4.7',
                },
            },
        },
        GAS_EQUIPMENTS_COMMON_STEP
    ),
    4.7: {
        group: StepsGroups.project,
        titleTranslationKey: '4.7.title',
        type: 'fields',
        fields: HeatingBillFields,
        getValidationSchema: getHeatingBillSchema,
        nextStep: (stepValues) =>
            !stepValues[routesTitles.CALCULATE_AIDS] || stepValues[routesTitles.CALCULATE_AIDS].value === 'house'
                ? '4.8.1'
                : '4.8.2',
        getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value || !currentStepValues.unit,
    },
    '4.8.1': merge(
        {
            nextStep: 4.11,
            group: StepsGroups.project,
            categories: {
                isolationVentilation: {
                    choices: {
                        attics: {
                            nextStep: '4.9.1',
                        },
                        walls: {
                            nextStep: '4.9.2',
                        },
                        floors: {
                            nextStep: '4.9.3',
                        },
                        windows: {
                            nextStep: '4.9.4',
                        },
                        flatRoof: {
                            nextStep: '4.9.5',
                        },
                    },
                },
                woodHeating: {
                    choices: {
                        fireplaceInsert: {
                            nextStep: '4.10',
                        },
                        woodStove: {
                            nextStep: '4.10',
                        },
                        woodBoiler: {
                            nextStep: '4.10',
                        },
                    },
                },
                solar: {
                    choices: {
                        combinedSolarSystem: {
                            nextStep: '4.10',
                        },
                        photovoltaicSolarPanels: {
                            nextStep: '4.10',
                        },
                        aerovoltaticPanels: {
                            nextStep: '4.10',
                        },
                    },
                },
                heatPump: {
                    choices: {
                        airWaterHeatPump: {
                            nextStep: '4.9.6',
                        },
                        geothermalHeatPump: {
                            nextStep: '4.9.6',
                        },
                        airAirHeatPump: {
                            nextStep: '4.9.6',
                        },
                        hybridHeatPump: {
                            nextStep: '4.9.6',
                        },
                    },
                },
                traditionalHeating: {
                    choices: {
                        oilCondensingBoiler: {
                            nextStep: '4.10',
                        },
                        gasCondensingBoiler: {
                            nextStep: '4.10',
                        },
                        inertiaRadiator: {
                            nextStep: '4.9.7',
                        },
                    },
                },
                waterHeater: {
                    choices: {
                        individualSolarWaterHeater: {
                            nextStep: '4.9.8',
                        },
                        thermodynamicWaterHeater: {
                            nextStep: '4.10',
                        },
                    },
                },
            },
        },
        HOUSE_WORKS_COMMON_STEP
    ),
    '4.8.2': merge(
        {
            nextStep: 4.11,
            group: StepsGroups.project,
            categories: {
                isolationVentilation: {
                    choices: {
                        walls: {
                            nextStep: '4.9.2',
                        },
                        windows: {
                            nextStep: '4.9.4',
                        },
                        flatRoof: {
                            nextStep: '4.9.5',
                        },
                    },
                },
                heatPump: {
                    choices: {
                        airWaterHeatPump: {
                            nextStep: '4.9.6',
                        },
                        geothermalHeatPump: {
                            nextStep: '4.9.6',
                        },
                        airAirHeatPump: {
                            nextStep: '4.10',
                        },
                        hybridHeatPump: {
                            nextStep: '4.9.6',
                        },
                    },
                },
                traditionalHeating: {
                    choices: {
                        oilCondensingBoiler: {
                            nextStep: '4.10',
                        },
                        gasCondensingBoiler: {
                            nextStep: '4.10',
                        },
                        inertiaRadiator: {
                            nextStep: '4.9.7',
                        },
                    },
                },
                waterHeater: {
                    choices: {
                        thermodynamicWaterHeater: {
                            nextStep: '4.10',
                        },
                    },
                },
            },
        },
        APARTMENT_WORKS_COMMON_STEP
    ),
    '4.9.1': merge(
        {
            group: StepsGroups.project,
            choices: {
                lostAttics: {
                    nextStep: '4.9.1.1',
                },
                convertedAttics: {
                    nextStep: '4.9.1.1',
                },
            },
        },
        ATTICS_ISOLATION_TYPES_COMMON_STEP
    ),
    '4.9.1.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        ATTICS_ISOLATION_SURFACE_COMMON_STEP
    ),
    '4.9.2': merge(
        {
            group: StepsGroups.project,
            choices: {
                interior: {
                    nextStep: '4.9.2.1',
                },
                exterior: {
                    nextStep: '4.9.2.1',
                },
                notKnown: {
                    nextStep: '4.9.2.1',
                },
            },
        },
        WALLS_ISOLATION_TYPES_COMMON_STEP
    ),
    '4.9.2.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        WALLS_ISOLATION_SURFACE_COMMON_STEP
    ),
    '4.9.3': merge(
        {
            group: StepsGroups.project,
            choices: {
                cave: {
                    nextStep: '4.9.3.1',
                },
                platform: {
                    nextStep: '4.9.3.1',
                },
                crawlspace: {
                    nextStep: '4.9.3.1',
                },
                garage: {
                    nextStep: '4.9.3.1',
                },
            },
        },
        BASEMENT_ISOLATION_TYPES_COMMON_STEP
    ),
    '4.9.3.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        BASEMENT_ISOLATION_SURFACE_COMMON_STEP
    ),
    '4.9.4': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        WINDOWS_ISOLATION_COUNT_COMMON_STEP
    ),
    '4.9.5': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        ROOF_ISOLATION_SURFACE_COMMON_STEP
    ),
    '4.9.6': merge(
        {
            group: StepsGroups.project,
            choices: {
                lowTemperature: {
                    nextStep: '4.10',
                },
                mediumTemperature: {
                    nextStep: '4.10',
                },
                highTemperature: {
                    nextStep: '4.10',
                },
                notKnown: {
                    nextStep: '4.10',
                },
            },
        },
        HEAT_PUMP_TYPES_COMMON_STEP
    ),
    '4.9.7': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP
    ),
    '4.9.8': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.10',
        },
        SOLAR_PANELS_SURFACE_COMMON_STEP
    ),
    '4.10': {
        group: StepsGroups.project,
        titleTranslationKey: '4.10.title',
        type: 'fields',
        fields: EstimatedBudgetFields,
        getValidationSchema: getDefaultSchema('number', 0, 100000),
        nextStep: '4.11',
        getShouldButtonBeDisabled: (currentStepValues) => !currentStepValues.value,
    },
    4.11: merge(
        {
            group: StepsGroups.project,
            choices: {
                thinking: {
                    nextStep: '4.12',
                },
                searchingForCraftsman: {
                    nextStep: '4.12',
                },
                aboutToSignQuotation: {
                    nextStep: '4.12',
                },
                alreadySignedQuotation: {
                    nextStep: '4.12',
                },
            },
        },
        CURRENT_PROJECT_STEP_COMMON_STEP
    ),
    4.12: {
        group: StepsGroups.project,
        titleTranslationKey: '4.12.title',
        subTitleTranslationKey: '4.12.subTitle',
        type: 'choices',
        choices: {
            yes: {
                icon: '/assets/svg/yes.svg',
                translationKey: '4.12.choices.yes',
                nextStep: '4.12.1',
            },
            no: {
                icon: '/assets/svg/no.svg',
                translationKey: '4.12.choices.no',
                nextStep: '4.13',
            },
        },
    },
    '4.12.1': {
        group: StepsGroups.project,
        titleTranslationKey: '4.12.1.title',
        subTitleTranslationKey: '4.12.1.subTitle',
        type: 'choices',
        choices: {
            transitionTaxCredit: {
                icon: '/assets/png/CITE.png',
                translationKey: '4.12.1.choices.transitionTaxCredit',
                nextStep: '4.13',
            },
            noRateEcoLoan: {
                icon: '/assets/png/eco-pret.png',
                translationKey: '4.12.1.choices.noRateEcoLoan',
                nextStep: '4.13',
            },
            anah: {
                icon: '/assets/png/ANAH.png',
                translationKey: '4.12.1.choices.anah',
                nextStep: '4.13',
            },
            others: {
                icon: '/assets/svg/help.svg',
                translationKey: '4.12.1.choices.others',
                nextStep: '4.13',
            },
        },
    },
    4.13: {
        group: StepsGroups.project,
        titleTranslationKey: '4.13.title',
        subTitleTranslationKey: '4.13.subTitle',
        type: 'choices',
        choices: {
            ownerOccupied: {
                icon: '/assets/svg/owner-occupied.svg',
                translationKey: '4.13.choices.ownerOccupied',
                nextStep: '4.14',
            },
            secondHomeOwner: {
                icon: '/assets/svg/second-home-owner.svg',
                translationKey: '4.14.choices.secondHomeOwner',
                nextStep: '4.13.1',
            },
            landlordOwner: {
                icon: '/assets/svg/landlord-owner.svg',
                translationKey: '4.14.choices.landlordOwner',
                nextStep: '4.13.1',
            },
            tenant: {
                icon: '/assets/svg/tenant.svg',
                translationKey: '4.13.choices.tenant',
                nextStep: '4.14',
            },
        },
    },
    '4.13.1': merge(
        {
            group: StepsGroups.project,
            nextStep: '4.14',
        },
        PRINCIPAL_RESIDENCE_POSTAL_CODE_COMMON_STEP
    ),
    4.14: merge(
        {
            group: StepsGroups.infos,
            nextStep: '4.15',
        },
        HOUSING_MEMBERS_COUNT_COMMON_STEP
    ),
    4.15: merge(
        {
            group: StepsGroups.infos,
            nextStep: '4.16',
        },
        TAX_INCOME_NUMBER_COMMON_STEP
    ),
    4.16: merge(
        {
            group: StepsGroups.infos,
            nextStep: routesTitles.CALCUL_AIDS_RESULTS,
            // runFunction: pushDatas,
            // replace: urlEndGeneral,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
    // 4.17: {
    //     group: StepsGroups.eligibility,
    //     titleTranslationKey: '4.17.title',
    //     type: 'content',
    //     content: () => 'TOP',
    // },
});
