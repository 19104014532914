import { animated, config, useSpring, useTransition } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/svg/check.svg';
import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import { ApplicationContext } from '../../commons/Application/Application.context';
import { Typography } from '../Typography/Typography';
import classes from './ChoiceItem.module.sass';
import { SELECTED_OVERLAY_TRANSITIONS, CHOICE_ITEM_SPRING_PROPS } from './ChoiceItem.springProps';

const getSpringProps = (isHovering, isSelected) => {
    if (isSelected) {
        return CHOICE_ITEM_SPRING_PROPS.selected;
    }
    if (isHovering) {
        return CHOICE_ITEM_SPRING_PROPS.hovering;
    }
    return CHOICE_ITEM_SPRING_PROPS.default;
};

export const ChoiceItem = ({ icon, label, isSelected, onClick, classes: receivedClasses = {} }) => {
    const { isSmall } = React.useContext(ApplicationContext);
    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();

    const styles = useSpring({
        ...getSpringProps(isHovering, isSelected),
        config: config.stiff,
    });

    const checkedIconStyles = useSpring({
        scale: isSelected ? 1 : 0.5,
        config: config.wobbly,
    });

    const selectedCircleOverlayTransitions = useTransition(isSelected, SELECTED_OVERLAY_TRANSITIONS);

    return (
        <animated.button
            type="button"
            className={cn(classes.root, !icon && classes.withoutIcon, isSmall && classes.small, receivedClasses.root)}
            onFocus={setIsHovering}
            onBlur={setIsNotHovering}
            onMouseEnter={setIsHovering}
            onMouseLeave={setIsNotHovering}
            style={{
                boxShadow: styles.boxShadow,
                borderColor: styles.borderColor,
            }}
            {...{ onClick }}
        >
            {icon && <img src={icon} className={classes.icon} alt={label} />}
            <Typography
                classes={{ root: classes.label }}
                component={animated.span}
                style={{
                    color: styles.color,
                }}
            >
                {label}
            </Typography>
            <div className={classes.circle}>
                {selectedCircleOverlayTransitions(
                    ({ opacity }, item) =>
                        item && (
                            <animated.div className={classes.selectedCircleOverlay} style={{ opacity }}>
                                <animated.span
                                    className={classes.checkIconContainer}
                                    style={{ transform: checkedIconStyles.scale.to((value) => `scale(${value})`) }}
                                >
                                    <CheckIcon className={classes.checkIcon} />
                                </animated.span>
                            </animated.div>
                        )
                )}
            </div>
        </animated.button>
    );
};
