/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { ApplicationContext } from '../components/commons/Application/Application.context';
import { STEPS } from '../components/routesComponents/Steps/Steps.data';
import { routesTitles } from '../components/routesComponents/Steps/Steps.titles';
import { extractChoicesFromCategories } from '../helpers';

const LOCAL_STORAGE_VALUES_KEY = 'eligibilityFormValues';
const DEFAULT_BASE_STEP = routesTitles.GENERAL_SHORT;

const getNextStepsFromChoices = (choices) =>
    Object.values(choices).map(({ nextStep: choiceNextStep }) => choiceNextStep);

const buildEncodedPathsHistory = (pathsHistory) => btoa(JSON.stringify(pathsHistory));

/*
type UseStepsUtils = () => {
    values: {
        [stepId: string]: {
            [key: string]: any;
        };
    };
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    goToStep: () => void;
    currentStepId: string;
    currentStep: FormStep;
    progression: number;
    canGoBack: boolean;
    wentToPreviousStep: boolean;
};

See schemas for keys references if step has type 'fields'.
If type is 'choices', then values[stepId] will only contains value: string or string[].
*/
export const useStepsUtils = () => {
    const params = useParams();
    const { search } = useLocation();

    const history = useHistory();
    const { language } = React.useContext(ApplicationContext);
    const [loader, setLoader] = React.useState(false);
    const [values, setValues] = React.useState({});
    const [wentToPreviousStep, setWentToPreviousStep] = React.useState(false);

    const baseStep = React.useMemo(() => {
        if (params.stepId && STEPS[params.stepId]) {
            return params.stepId;
        }
        if (params.stepIdOrEncodedPathsHistory && STEPS[params.stepIdOrEncodedPathsHistory]) {
            return params.stepIdOrEncodedPathsHistory;
        }
        return null;
    }, [params.stepId, params.stepIdOrEncodedPathsHistory]);

    const setNewPathsHistory = React.useCallback(
        (newPathsHistory) => {
            history.push(`${baseStep ? `/${baseStep}` : ''}/${buildEncodedPathsHistory(newPathsHistory)}${search}`);
        },
        [baseStep, history]
    );

    // Contains all paths.
    const pathsHistory = React.useMemo(() => {
        let toDecode;
        if (baseStep && params.encodedPathsHistory) {
            toDecode = params.encodedPathsHistory;
        } else if (params.stepIdOrEncodedPathsHistory && !baseStep) {
            toDecode = params.stepIdOrEncodedPathsHistory;
        }
        if (!toDecode) {
            return null;
        }
        try {
            return JSON.parse(atob(toDecode));
        } catch (e) {
            return null;
        }
    }, [params.encodedPathsHistory, params.stepIdOrEncodedPathsHistory, baseStep]);

    /*
    This useEffect handles those cases:
    - If there is a baseStep but no encodedPathsHistory (Ex: /general-short)
    then builds a new pathsHistory containing [baseStep].
    - If there are no baseStep and (there is no stepIdOrEncodedPathsHistory OR
    (there is a stepIdOrEncodedPathsHistory which isn't a valid step id AND
    there is no pathsHistory, meaning that it is not a valid history)), than
    builds a new pathsHistory with DEFAULT_BASE_STEP.
    */
    React.useEffect(() => {
        if (baseStep && !params.encodedPathsHistory) {
            setNewPathsHistory([baseStep]);
            return;
        }
        if (
            !baseStep &&
            (!params.stepIdOrEncodedPathsHistory || (!STEPS[params.stepIdOrEncodedPathsHistory] && !pathsHistory))
        ) {
            setNewPathsHistory([DEFAULT_BASE_STEP]);
        }
    }, [baseStep, params.encodedPathsHistory, params.stepIdOrEncodedPathsHistory, history, setNewPathsHistory]);

    // Runs on first mount, will update 'values' state if localStorage entry exists.
    React.useEffect(() => {
        if (!localStorage) {
            return;
        }
        const savedValues = localStorage.getItem(LOCAL_STORAGE_VALUES_KEY);

        if (savedValues && Object.keys(savedValues).length > 1) {
            const parsedValues = JSON.parse(savedValues);
            setValues(parsedValues);
        }
    }, []);

    // Next step can be either 'string' or () => 'string'. See Steps.types.js.
    const buildNextStep = React.useCallback(
        (nextStep, newValues) => {
            if (typeof nextStep !== 'function') {
                return nextStep;
            }
            return nextStep(newValues || values);
        },
        [values]
    );

    // Will update 'values' state & save new values in localStorage.
    const saveValues = React.useCallback((newValues) => {
        setValues(newValues);
        if (!localStorage) {
            return;
        }
        localStorage.setItem(LOCAL_STORAGE_VALUES_KEY, JSON.stringify(newValues));
    }, []);

    const currentStepId = React.useMemo(() => pathsHistory && pathsHistory[pathsHistory.length - 1], [pathsHistory]);
    const currentStep = React.useMemo(() => STEPS[currentStepId], [currentStepId]);
    const canGoBack = React.useMemo(() => pathsHistory && pathsHistory.length > 1, [pathsHistory]);

    // Self explanatory.
    const goToStep = React.useCallback(
        (stepId) => {
            const newPathsHistory = [...pathsHistory, stepId];
            setNewPathsHistory(newPathsHistory);
        },
        [pathsHistory, setNewPathsHistory]
    );

    const getNextStepFromMultichoices = (currentStepValues, choices) => {
        for (const value of currentStepValues.value) {
            for (const choiceKey in choices) {
                if (choiceKey === value) {
                    if (choices[choiceKey].requiredNextStep) {
                        return choices[choiceKey].requiredNextStep;
                    }
                }
            }
        }
        return currentStep.requiredNextStep || currentStep.nextStep;
    };

    // ne traite que un niveau de choices, si le requiredNextStep est un choices contenant des différents requiredNextSteps pour chaque choix alors il va uniquement prendre le requiredNextStep du step (celui qui contient l'objet choices)
    const searchSubNextStep = () => {
        // on cherche dans toutes les routes si un multichoice est présent
        let getTheFirstRequiredNS = false;
        for (const path of pathsHistory) {
            const actualStep = STEPS[path];
            if (actualStep.multichoice) {
                // si multichoice, je récupère l'ensemble des choix, dans categories si categories, pour une gesiton plus simple
                const choices = Object.entries(
                    actualStep.categories ? extractChoicesFromCategories(actualStep.categories) : actualStep.choices
                    // filter les choices uniquement qui correspondent à value = []
                ).filter(
                    ([key]) => Array.isArray((values[path] || {}).value) && (values[path] || {}).value.includes(key)
                );
                for (const [, choice] of choices) {
                    if (choice.requiredNextStep) {
                        let ns = choice.requiredNextStep;
                        while (ns) {
                            if (ns === currentStepId) {
                                if (STEPS[ns].requiredNextStep) {
                                    return STEPS[ns].requiredNextStep;
                                }
                                getTheFirstRequiredNS = true;
                            } else if (getTheFirstRequiredNS) {
                                return choice.requiredNextStep;
                            }
                            ns = (STEPS[ns] || {}).requiredNextStep;
                        }
                    }
                }
                if (getTheFirstRequiredNS) {
                    return actualStep.requiredNextStep || actualStep.nextStep;
                }
            }
        }
        return false;
    };

    const updateToGoodOrderMultiChoices = (currentStepValues, choices) => ({
        value: Object.keys(choices).reduce(
            (valuesOrdered, choiceKey) =>
                currentStepValues.value.includes(choiceKey) ? [...valuesOrdered, choiceKey] : valuesOrdered,
            []
        ),
    });

    // Need to submit current step values before proceeding any further.
    const goToNextStep = React.useCallback(
        (currentStepValues) => {
            let valuesFromCurrentStep = { ...currentStepValues };
            let nextStep;
            if (currentStep.type === 'choices' && Array.isArray(currentStepValues.value)) {
                const choices = currentStep.categories
                    ? extractChoicesFromCategories(currentStep.categories)
                    : currentStep.choices;
                valuesFromCurrentStep = updateToGoodOrderMultiChoices(currentStepValues, choices);
                nextStep = getNextStepFromMultichoices(valuesFromCurrentStep, choices);
            } else if (currentStep.nextStep) {
                ({ nextStep } = currentStep);
            } else if (currentStep.requiredNextStep) {
                ({ requiredNextStep: nextStep } = currentStep);
            } else if (currentStep.type === 'choices') {
                // Values can be an array if multichoice is true || multichoice is defined.
                const value = Array.isArray(currentStepValues.value)
                    ? currentStepValues.value[0]
                    : currentStepValues.value;
                // Need to get choice's category is hasCategories is true.
                if (currentStep.hasCategories) {
                    ({ nextStep } = Object.values(currentStep.categories).find((category) =>
                        Object.keys(category.choices).includes(value)
                    ).choices[value]);
                } else {
                    ({ nextStep } = currentStep.choices[value]);
                }
            }
            // next step est bien à undefined
            const newValues = { ...values };
            newValues[currentStepId] = valuesFromCurrentStep;
            if (
                !nextStep ||
                (typeof nextStep !== 'function' &&
                    STEPS[nextStep].runFunction &&
                    typeof STEPS[nextStep].runFunction === 'function')
            ) {
                const { redirect, runFunction, replace } = STEPS[nextStep] || currentStep;
                if (
                    !(
                        runFunction &&
                        runFunction.name === 'pushDatas' &&
                        nextStep &&
                        nextStep !== routesTitles.CALCUL_AIDS_RESULTS
                    ) &&
                    typeof runFunction === 'function'
                ) {
                    const pathsRelatedValues = pathsHistory.reduce(
                        (acc, path) => ({
                            ...acc,
                            [path]: newValues[path],
                        }),
                        {}
                    );
                    if (!nextStep && redirect) {
                        runFunction(pathsRelatedValues, pathsHistory[0], search, language, () => window.open(redirect));
                    } else if (!nextStep && replace) {
                        runFunction(pathsRelatedValues, pathsHistory[0], search, language, () =>
                            (window.parent || window).location.replace(replace)
                        );
                        setLoader(true);
                    } else {
                        runFunction(pathsRelatedValues, pathsHistory[0], search, language);
                    }
                } else {
                    if (!nextStep && redirect) {
                        window.open(redirect);
                    }
                    if (!nextStep && replace) {
                        (window.parent || window).location.replace(replace);
                        setLoader(true);
                    }
                }
                if (!nextStep) {
                    nextStep = searchSubNextStep();
                    if (nextStep === false) {
                        nextStep = undefined;
                    }
                }
            }
            setWentToPreviousStep(false);
            saveValues(newValues);
            goToStep(buildNextStep(nextStep, newValues));
        },
        [values, currentStepId, currentStep, buildNextStep, saveValues, goToStep, pathsHistory]
    );

    // Pop current path & set new pathsHistory.
    const goToPreviousStep = React.useCallback(() => {
        setWentToPreviousStep(true);
        const newPathsHistory = [...pathsHistory];
        newPathsHistory.pop();
        setNewPathsHistory(newPathsHistory);
    }, [setNewPathsHistory, pathsHistory]);

    const getUniqueNextStepsFromStepId = React.useCallback(
        (stepId) => {
            const step = STEPS[stepId];
            let nextSteps = [];
            if (step) {
                const { nextStep, categories, choices } = step;
                if (nextStep) {
                    nextSteps = [buildNextStep(nextStep)];
                } else {
                    if (categories) {
                        nextSteps = Object.values(categories).reduce(
                            (acc, { choices: categoryChoices }) => [
                                ...acc,
                                ...getNextStepsFromChoices(categoryChoices),
                            ],
                            []
                        );
                    }
                    if (choices) {
                        nextSteps = getNextStepsFromChoices(choices);
                    }
                }
            }
            return nextSteps.reduce((acc, receivedNextStep) => {
                if (acc.includes(receivedNextStep)) {
                    return acc;
                }
                return [...acc, buildNextStep(receivedNextStep)];
            }, []);
        },
        [buildNextStep]
    );

    /*
    Get maximum possible remaining paths count & return percentage based on it.
    Example with currentStepId: 1.1 = 1.1 -> 1.2 -> 1.2.1 -> = 2 remaining steps count. Not the largest count.
                                          -> 1.3.1 -> 1.4 -> ... -> 1.9 = 8 remaining steps count. Will be based on it.
                                          -> ...
    There might be an easier way, but it works fine.
    */
    const progression = React.useMemo(() => {
        if (!pathsHistory) {
            return 0;
        }
        let maxRemainingStepsCount = 0;

        const loopNextSteps = (nextSteps, currentNextStepsCount) => {
            if (maxRemainingStepsCount < currentNextStepsCount) {
                maxRemainingStepsCount = currentNextStepsCount;
            }
            nextSteps.forEach((stepId) => {
                loopNextSteps(getUniqueNextStepsFromStepId(stepId), currentNextStepsCount + 1);
            });
        };

        loopNextSteps(getUniqueNextStepsFromStepId(currentStepId), 0);

        return ((pathsHistory.length - 1) * 100) / (pathsHistory.length - 1 + maxRemainingStepsCount);
    }, [currentStepId, pathsHistory, getUniqueNextStepsFromStepId]);

    const { title = STEPS[DEFAULT_BASE_STEP].title, sideTitle = STEPS[DEFAULT_BASE_STEP].sideTitle } =
        STEPS[baseStep] || STEPS[DEFAULT_BASE_STEP];
    const buttonText =
        currentStep && currentStep.buttonText ? language.steps[currentStep.buttonText] : language.steps.continue;
    return {
        values,
        goToNextStep,
        goToPreviousStep,
        goToStep,
        currentStepId,
        currentStep,
        progression,
        canGoBack: loader ? false : canGoBack,
        wentToPreviousStep,
        title,
        sideTitle,
        buttonText,
        loader,
    };
};
