import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import classes from './TextField.module.sass';
import { getTextFieldSpringProps } from './TextField.springProps';

export const TextField = React.forwardRef(
    (
        {
            component: Component = 'input',
            onFocus,
            onBlur,
            classes: receivedClasses = {},
            children,
            afterInput,
            ...other
        },
        ref
    ) => {
        const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
        const [isFocused, setIsFocused, setIsNotFocused] = useCallbackOpen();

        const handleFocus = React.useCallback(
            (event) => {
                if (typeof onFocus === 'function') {
                    onFocus(event);
                }
                setIsFocused();
            },
            [onFocus, setIsFocused]
        );

        const handleBlur = React.useCallback(
            (event) => {
                if (typeof onBlur === 'function') {
                    onBlur(event);
                }
                setIsNotFocused();
            },
            [onBlur, setIsNotFocused]
        );

        const springProps = useSpring(getTextFieldSpringProps(isHovering, isFocused));

        return (
            <animated.div
                className={cn(classes.root, Boolean(afterInput) && classes.withAfterInput, receivedClasses.root)}
                style={springProps}
            >
                <Component
                    className={classes.input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onMouseEnter={setIsHovering}
                    onMouseLeave={setIsNotHovering}
                    {...{ ref }}
                    {...other}
                >
                    {children}
                </Component>
                <div className={classes.afterInput}>{afterInput}</div>
            </animated.div>
        );
    }
);
