import cn from 'classnames';
import React from 'react';

import classes from './Typography.module.sass';

// See https://www.figma.com/file/3zIW8GUO2eqtilzPXf7E8p/SIMULATEUR-FLAMMEBLEUE?node-id=8%3A2.

export const Typography = ({
    // Variant can be either 'body', 'title', 'subTitle1', 'subTitle2' or 'button'. Default is 'body'.
    variant = 'body',
    component: Component = 'span',
    classes: receivedClasses = {},
    children,
    ...other
}) => (
    <Component className={cn(classes.root, classes[variant], receivedClasses.root)} {...other}>
        {children}
    </Component>
);
