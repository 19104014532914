import { merge, isEmpty, isObject } from 'lodash';
import qs from 'query-string';

import { STEPS } from './components/routesComponents/Steps/Steps.data';
import { isIdf, getPrimes } from './components/routesComponents/Steps/contents/CalculAids/helpers';
import {
    revenuAboutNumbersOfPersonsAdditionnal,
    revenuAboutNumbersOfPersons,
} from './components/routesComponents/Steps/contents/CalculAids/variables';
import { appendSpreadsheet } from './utils/google';
import { addLeadToSibList } from './utils/sendingBlue';
import { sendToCRM } from './webAbondance';

export const redirectUrlDefault = 'https://www.flammebleue-environnement.fr/bienvenue-chez-flammebleue-environnement/';
export const extractChoicesFromCategories = (categories) =>
    Object.values(categories).reduce((obj, { choices }) => ({ ...obj, ...choices }), {});
const tags = {
    'renovation-globale': 'Réno Globale',
    pac: 'PAC',
    'chauffer-sa-maison': 'PAC',
    'rafraichir-sa-maison': 'PAC',
    'chauffer-l-eau-de-ma-maison': 'PAC',
    'isoler-sa-maison': 'ISO',
    'isoler-ma-maison': 'ISO',
    isolations: 'ISO',
    'ventiler-sa-maison': 'VMC',
    'ventiler-ma-maison': 'VMC',
    'produire-son-electricite-panneau-photovoltaique': 'PV',
    'contact-flammebleue-environnement': 'Général',
    '': 'Général',
    'general-short': 'Général',
    home: 'Général',
    isolation: 'ISO',
    'change-heating': 'PAC',
    'apply-for-bonus': 'BONUS',
    'calculate-aids': 'CALCUL AIDE',
    'chauffer-ma-maison': 'PAC',
    'rafraichir-ma-maison': 'PAC',
    'renover-ma-maison': 'Réno Globale',
};

function addZ(n) {
    return (n < 10 ? '0' : '') + n;
}

const getSurface = (body) =>
    body.surface_habitable_chauffe ||
    body.surface_mur ||
    body.lost_attics_isolation_surface ||
    body.converted_attics_isolation_surface ||
    body.lost_and_converted_attics_isolation_surface;

export const colors = ['blue', 'yellow', 'violet', 'pink'];
export const getColor = (body) => {
    const { taxIncomeNumber, nbr_persons = 1, zipCode } = body;
    const idf = isIdf(zipCode);
    const addAdditionnalToRevenusArr = () => {
        const additionnal = revenuAboutNumbersOfPersonsAdditionnal(idf).map((r) => r * (nbr_persons - 5));
        return revenuAboutNumbersOfPersons(idf)[5 - 1].map((r, index) => r + additionnal[index]);
    };
    const arrOfRevenus =
        nbr_persons > 5 ? addAdditionnalToRevenusArr() : revenuAboutNumbersOfPersons(idf)[nbr_persons - 1];
    for (let index = 0; index < arrOfRevenus.length; index++) {
        const revenu = arrOfRevenus[index];
        if (taxIncomeNumber <= revenu) {
            return colors[index];
        }
    }
    return colors[colors.length - 1];
};

const variablesStepFields = (body, toDelete = true) => {
    if (body.attics_type) {
        switch (body.attics_type) {
            case 'lostAttics':
                body.lost_attics_height_under_ceiling = body.height_under_ceiling;
                body.lost_attics_isolation_surface = body.surface_habitable_chauffe;
                break;
            case 'convertedAttics':
                body.converted_attics_height_under_ceiling = body.height_under_ceiling;
                body.converted_attics_isolation_surface = body.surface_habitable_chauffe;
                break;
            case 'multipleAttics':
                body.lost_and_converted_attics_height_under_ceiling = body.height_under_ceiling;
                body.lost_and_converted_attics_isolation_surface = body.surface_habitable_chauffe;
                break;
            default:
                break;
        }
        if (toDelete) {
            delete body.height_under_ceiling;
            delete body.surface_habitable_chauffe;
        }
    }

    ['crawlspace', 'basement', 'garage', 'interior'].map((element) => {
        if (body.isolation_needs_type === element || body.basement_isolation_type === element) {
            body[`${element}_isolation_surface`] = body.surface_habitable_chauffe;
            if (toDelete) {
                delete body.surface_habitable_chauffe;
            }
            body.height_under_ceiling && (body[`${element}_height_under_ceiling`] = body.height_under_ceiling);
            if (toDelete) {
                body.height_under_ceiling && delete body.height_under_ceiling;
            }
        }
    });
    // si toute l'habitation alors on ne touche pas les variables actuelles (surface_habitable_chauffe)
};

const formatZipCode = (zipCode = '') => {
    let str = '';
    for (let index = 0; index < 5 - zipCode.length; index++) {
        str += '0';
    }
    return str + String(zipCode);
};

export const pushDatas = async (values, campaignName, search, { steps: language }, runFunc = () => {}) => {
    const choicesArr = {};
    const body = Object.entries(values).reduce((acc, [stepId, stepValues]) => {
        if (!stepValues) {
            return { ...acc };
        }
        if (STEPS[stepId].type === 'choices') {
            const choices = STEPS[stepId].categories
                ? extractChoicesFromCategories(STEPS[stepId].categories)
                : STEPS[stepId].choices;

            choicesArr[STEPS[stepId].name || 'value'] = (
                Array.isArray(stepValues.value)
                    ? stepValues.value.map((value) => choices[value])
                    : [choices[stepValues.value]]
            )
                .map((value) => language[value.translationKey])
                .join(', ');
        }
        if (stepValues.value) {
            return {
                ...acc,
                [STEPS[stepId].name || 'value']: stepValues.value,
            };
        }
        return {
            ...acc,
            ...stepValues,
        };
    }, {});
    if (body.first_name && body.last_name) {
        body.full_name = `${body.first_name} ${body.last_name}`;
    }
    body.campaign = campaignName;
    body.tag = tags[campaignName || ''];
    const { utm_source: utmSource = 'organic' } = qs.parse(search);
    body.utmSource = utmSource;
    body.utmParameters = search;
    const today = new Date();
    body.day = addZ(today.getDate());
    body.month = addZ(today.getMonth() + 1);
    body.year = today.getFullYear().length === 2 ? `20${today.getFullYear()}` : today.getFullYear();
    body.date = `${body.day}/${body.month}/${body.year}`;
    body.fullDate = `${body.date} ${today.toLocaleTimeString()}`;
    body.statut = 'Non attribué';
    body.zipCode = formatZipCode(body.zipCode);
    body.type_heating = body.type_heating || choicesArr.type_energie;
    body['814c739850155e4e6776b33e5c2db929c243257cd8a6eb3d3d'] = getSurface(body);
    if (body && !isEmpty(body) && isObject(body) && body.zipCode && body.taxIncomeNumber && body.worksWishCategories) {
        const color = getColor(body);
        const { worksWishCategories } = body;
        const primesCalculated = getPrimes(worksWishCategories, colors.indexOf(color), body);
        body.primes = primesCalculated;
    }
    body.JSON = JSON.stringify(body);
    const bodyWithoutVariablesDeleted = { ...body };
    variablesStepFields(bodyWithoutVariablesDeleted, false);
    try {
        addLeadToSibList(body.emails);
        await appendSpreadsheet(merge(bodyWithoutVariablesDeleted, choicesArr));
        variablesStepFields(body);
        // sendToCRM(merge(body, choicesArr));
        runFunc();
        console.log('mergedBody :>> ', merge(body, choicesArr));
    } catch (e) {
        console.log("Erreur lors de l'envoie", e);
    }
    // mergons pour obtenir les réponses readeable
};
