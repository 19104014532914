import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { useCallbackOpen } from '../../../hooks/useCallbackOpen';
import { ApplicationContext } from '../../commons/Application/Application.context';
import { Typography } from '../Typography/Typography';
import classes from './Button.module.sass';
import { getButtonSpringProps } from './Button.springProps';

export const Button = ({ disabled = false, children, onClick, classes: receivedClasses = {}, ...other }) => {
    const [isHovering, setIsHovering, setIsNotHovering] = useCallbackOpen();
    const buttonStyles = useSpring(getButtonSpringProps(disabled, isHovering));
    const { isSmall } = React.useContext(ApplicationContext);
    const handleClick = React.useCallback(
        (event) => {
            if (disabled) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [disabled, onClick]
    );

    return (
        <animated.button
            type="button"
            className={cn(classes.root, disabled && classes.disabled, receivedClasses.root, isSmall && classes.small)}
            style={buttonStyles}
            onFocus={setIsHovering}
            onBlur={setIsNotHovering}
            onMouseEnter={setIsHovering}
            onMouseLeave={setIsNotHovering}
            onClick={handleClick}
            {...other}
        >
            <Typography classes={{ root: classes.typography }} variant="button">
                {children}
            </Typography>
        </animated.button>
    );
};
