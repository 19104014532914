import { animated, useTransition } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../commons/Application/Application.context';
import { StepTitles } from '../StepTitles/StepTitles';
import { StepsUtilsContext } from '../Steps.context';
import { STEPS } from '../Steps.data';
import classes from './CurrentStep.module.sass';
import { getCurrentStepTransitions } from './CurrentStep.springProps';
import { Form } from './Form/Form';

export const WithTransitionsCurrentStep = (props) => {
    const { currentStepId, wentToPreviousStep } = React.useContext(StepsUtilsContext);
    const transitions = useTransition(currentStepId, getCurrentStepTransitions(wentToPreviousStep));

    return (
        <div className={classes.transitionsContainer}>
            {transitions(
                (styles, item) => Boolean(item) && <Step stepId={item} step={STEPS[item]} style={styles} {...props} />
            )}
        </div>
    );
};

const Step = ({ step, stepId, style, classes: receivedClasses = {} }) => {
    const { titleTranslationKey, subTitleTranslationKey, miniSubTitle = false, coloredLastTitle = null } = step;
    const { isSmall } = React.useContext(ApplicationContext);

    return (
        <animated.div className={cn(classes.root, receivedClasses.root, isSmall && classes.small)} {...{ style }}>
            {Boolean(titleTranslationKey || subTitleTranslationKey) && (
                <StepTitles {...{ titleTranslationKey, subTitleTranslationKey, miniSubTitle, coloredLastTitle }} />
            )}
            <StepContent {...{ step, stepId }} />
        </animated.div>
    );
};

const StepContent = ({ step, stepId }) => {
    const { type } = step;

    if (type === 'content') {
        const { content: Content } = step;
        return <Content />;
    }

    if (type === 'choices' || type === 'fields') {
        return <Form key={`step_form_${stepId}`} {...{ step, stepId }} />;
    }

    return null;
};
