import * as yup from 'yup';

export const getHeatingBillSchema = (translations) =>
    yup.object().shape({
        value: yup
            .number()
            .min(0, translations.min(0))
            .max(10000, translations.max(1000))
            .required(translations.required),
        unit: yup.string().required(translations.required).oneOf(['month', 'year']),
    });
