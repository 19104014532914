import { StepsGroups } from './Groups/Groups.data';
import { routesTitles } from './Steps.titles';
import { PAC_STEPS } from './steps/PacSteps';
import { APPLY_FOR_BONUS_STEPS } from './steps/applyForBonusSteps';
import { CALCULATE_AIDS_STEPS } from './steps/calculateAidsSteps';
import { CHANGE_HEATING_STEPS } from './steps/changeHeatingSteps';
import {
    GENERAL_INEGIBILITY_COMMON_STEP,
    GENERAL_EGIBILITY_COMMON_STEP,
    WALLS_ISOLATION_TYPES_COMMON_STEP,
    WALLS_ISOLATION_SURFACE_COMMON_STEP,
    HEAT_PUMP_TYPES_COMMON_STEP,
    ATTICS_ISOLATION_TYPES_COMMON_STEP,
    ATTICS_ISOLATION_SURFACE_COMMON_STEP,
    BASEMENT_ISOLATION_TYPES_COMMON_STEP,
    BASEMENT_ISOLATION_SURFACE_COMMON_STEP,
    WINDOWS_ISOLATION_COUNT_COMMON_STEP,
    RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP,
    SOLAR_PANELS_SURFACE_COMMON_STEP,
    ROOF_ISOLATION_SURFACE_COMMON_STEP,
    CALCUL_AIDS_COMMON_STEP,
} from './steps/commonsSteps';
import { GENERAL_SHORT_STEPS } from './steps/generalShortSteps';
import { HEAT_HOME_STEPS } from './steps/heatHomeSteps';
import { HEAT_HOME_WATER_STEPS } from './steps/heatHomeWaterSteps';
import { ISOLATE_HOME_STEPS } from './steps/isolateHomeSteps';
import { ISOLATION_STEPS } from './steps/isolationSteps';
import { ISOLATIONS_STEPS } from './steps/isolationsSteps';
import { REFRESH_HOME_STEPS } from './steps/refreshHomeSteps';
import { RENOVE_HOME_STEPS } from './steps/renoveHomeSteps';
import { VENTILATE_HOME_STEPS } from './steps/ventilateHomeSteps';
/*
See Figma file for steps references.
Remove home, 1.x.x, 2.x.x, 3.x.x, 4.x.x if they are not needed.
*/
export const STEPS = Object.freeze({
    [routesTitles.HOME]: {
        group: StepsGroups.needs,
        title: 'renovationEligibility',
        titleTranslationKey: 'home.title',
        subTitleTranslationKey: 'home.subTitle',
        name: 'work_type',
        type: 'choices',
        choices: {
            isolateHouse: {
                icon: '/assets/svg/house.svg',
                translationKey: 'home.choices.isolateHouse',
                nextStep: routesTitles.ISOLATION,
            },
            changeHeating: {
                icon: '/assets/svg/radiator.svg',
                translationKey: 'home.choices.changeHeating',
                nextStep: routesTitles.CHANGE_HEATING,
            },
            // applyForBonus: {
            //     icon: '/assets/svg/bonus.svg',
            //     translationKey: 'home.choices.applyForBonus',
            //     nextStep: routesTitles.APPLY_FOR_BONUS,
            // },
            calculateAids: {
                icon: '/assets/svg/calculate.svg',
                translationKey: 'home.choices.calculateAids',
                nextStep: routesTitles.CALCULATE_AIDS,
            },
        },
    },
    ...ISOLATION_STEPS, // 1.x.x
    ...CHANGE_HEATING_STEPS, // 2.x.x
    ...APPLY_FOR_BONUS_STEPS, // 3.x.x
    ...CALCULATE_AIDS_STEPS, // 4.x.x
    ...HEAT_HOME_WATER_STEPS, // 5.x.x
    ...HEAT_HOME_STEPS, // 6.x.x
    ...ISOLATE_HOME_STEPS, // 7.x.x
    ...REFRESH_HOME_STEPS, // 8.x.x
    ...RENOVE_HOME_STEPS, // 9.x.x
    ...VENTILATE_HOME_STEPS, // 10.x.x
    ...GENERAL_SHORT_STEPS, // 11.x.x
    ...ISOLATIONS_STEPS, // 12.x.x
    ...PAC_STEPS, // 13.x.x
    [routesTitles.GENERAL_INEGIBILITY]: GENERAL_INEGIBILITY_COMMON_STEP,
    [routesTitles.GENERAL_ELIGIBILITY]: GENERAL_EGIBILITY_COMMON_STEP,
    [routesTitles.CALCUL_AIDS_RESULTS]: CALCUL_AIDS_COMMON_STEP,
    WALLS_ISOLATION_TYPES_COMMON_STEP: { ...WALLS_ISOLATION_TYPES_COMMON_STEP },
    WALLS_ISOLATION_SURFACE_COMMON_STEP: { ...WALLS_ISOLATION_SURFACE_COMMON_STEP },
    HEAT_PUMP_TYPES_COMMON_STEP: { ...HEAT_PUMP_TYPES_COMMON_STEP },
    ATTICS_ISOLATION_TYPES_COMMON_STEP: { ...ATTICS_ISOLATION_TYPES_COMMON_STEP },
    ATTICS_ISOLATION_SURFACE_COMMON_STEP: { ...ATTICS_ISOLATION_SURFACE_COMMON_STEP },
    BASEMENT_ISOLATION_TYPES_COMMON_STEP: { ...BASEMENT_ISOLATION_TYPES_COMMON_STEP },
    BASEMENT_ISOLATION_SURFACE_COMMON_STEP: { ...BASEMENT_ISOLATION_SURFACE_COMMON_STEP },
    WINDOWS_ISOLATION_COUNT_COMMON_STEP: { ...WINDOWS_ISOLATION_COUNT_COMMON_STEP },
    ROOF_ISOLATION_SURFACE_COMMON_STEP: { ...ROOF_ISOLATION_SURFACE_COMMON_STEP },
    RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP: { ...RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP },
    SOLAR_PANELS_SURFACE_COMMON_STEP: { ...SOLAR_PANELS_SURFACE_COMMON_STEP },
});
