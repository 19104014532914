import cn from 'classnames';
import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { postalCodes } from '../../../../../postalCodesArr';
import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { FormField } from '../../../../commons/FormField/FormField';
import { TextField } from '../../../../designSystem/TextField/TextField';
import classes from '../PersonalInfosFields/PersonalInfosFields.module.sass';
import CityButton from './CityButton';

export const PostalCodeFields = ({ ...other }) => {
    const { language } = React.useContext(ApplicationContext);
    const {
        register,
        formState: { errors },
        watch,
        control,
        setValue,
    } = useFormContext();
    const postalCode = watch('zipCode');
    const [cities, setCities] = React.useState([]);
    React.useEffect(() => {
        if (!postalCode || !postalCodes[postalCode]) {
            setValue('city', '');
            setCities([]);
        } else if (!watch('city')) setCities(postalCodes[postalCode] || []);
    }, [postalCode]);

    return (
        <>
            <div className={classes.behindFieldsContainer}>
                <FormField
                    classes={{ root: classes.formField }}
                    label={language.steps['fields.postalCode.value.label']}
                    error={errors.postalCode}
                >
                    <TextField {...other} {...register('zipCode')} />
                    <div className={cn(classes.cities)}>
                        {cities.map((cityProposal, index) => (
                            <CityButton
                                key={index}
                                onClick={() => {
                                    setValue('city', cityProposal);
                                    setCities([]);
                                }}
                                city={cityProposal}
                            />
                        ))}
                    </div>
                </FormField>

                <Controller
                    name="city"
                    {...{ control }}
                    render={({ field: { value }, fieldState: { error } }) =>
                        Boolean(value) && (
                            <FormField
                                classes={{ root: classes.formField }}
                                label={language.steps['fields.city.value.label']}
                                error={error}
                            >
                                <TextField {...other} readOnly value={value} />
                            </FormField>
                        )
                    }
                />
            </div>
        </>
    );
};
