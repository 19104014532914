import * as yup from 'yup';

export const getPersonalInfosSchema = (translations) =>
    yup.object().shape({
        civilite: yup.string().required(translations.required).oneOf(['M', 'Mme'], translations.invalidType),
        first_name: yup.string().required(translations.required).min(2, translations.min(2)),
        last_name: yup.string().required(translations.required).min(2, translations.min(2)),
        phones: yup
            .string()
            .required(translations.required)
            .matches(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim, translations.invalidPhone),
        emails: yup.string().email(translations.invalidEmail).required(translations.required),
    });
