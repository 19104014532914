import { merge } from 'lodash';

import { pushDatas, redirectUrlDefault } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { mySecondRecurrentWay, PERSONAL_INFOS_COMMON_STEP } from './commonsSteps';

// 9.*.*
export const RENOVE_HOME_STEPS = Object.freeze({
    ...mySecondRecurrentWay(
        15,
        routesTitles.RENOVE_HOME,
        { title: 'renovateHome' /* subTitleTranslationKey: 'commons.currentHousingEnergyGlobale.subTitle' */ }
        // ['oilHeating', 'gasHeating']
    ),
    15.9: merge(
        {
            group: StepsGroups.infos,
            runFunction: pushDatas,
            replace: redirectUrlDefault,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
