import { merge } from 'lodash';

import { redirectUrlDefault, pushDatas } from '../../../../helpers';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import { PERSONAL_INFOS_COMMON_STEP, myFirstRecurrentWay } from './commonsSteps';

// 12.*.*
export const ISOLATIONS_STEPS = Object.freeze({
    ...myFirstRecurrentWay(12, routesTitles.ISOLATIONS, { title: 'isolateHome' }),
    12.9: merge(
        {
            group: StepsGroups.infos,
            replace: redirectUrlDefault,
            runFunction: pushDatas,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
});
