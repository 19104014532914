import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Button } from '../../../../designSystem/Button/Button';
import { Typography } from '../../../../designSystem/Typography/Typography';
import classes from './IsolationEligibility.module.sass';

export const IsolationEligibility = () => {
    const { language, isSmall } = React.useContext(ApplicationContext);

    return (
        <div className={cn(classes.root, isSmall && classes.small)}>
            <div className={classes.textsIllustrationContainer}>
                <div className={classes.texts}>
                    <Typography classes={{ root: classes.title }} variant="title">
                        {language.steps['contents.isolationEligibility.title']}
                        <span className={classes.bolden}>{'1€'}</span>
                    </Typography>
                    <div className={classes.listSmallText}>
                        <ul className={classes.list}>
                            <li>{language.steps['contents.isolationEligibility.list.1']}</li>
                            <li>{language.steps['contents.isolationEligibility.list.2']}</li>
                            <li>{language.steps['contents.isolationEligibility.list.3']}</li>
                        </ul>
                        <Typography classes={{ root: classes.smallText }}>
                            {language.steps['contents.isolationEligibility.smallText']}
                        </Typography>
                    </div>
                </div>
                <img
                    className={classes.illustration}
                    src="/assets/svg/lets-talk.svg"
                    alt={language.steps['contents.isolationEligibility.title']}
                />
            </div>
            <Button classes={{ root: classes.button }}>{language.steps['contents.isolationEligibility.button']}</Button>
        </div>
    );
};
