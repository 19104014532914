import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../commons/Application/Application.context';
import { StepsUtilsContext } from '../Steps.context';
import { StepsProgressBar } from '../StepsProgressBar/StepsProgressBar';
import { GoBackButton } from './GoBackButton/GoBackButton';
import classes from './StepHeader.module.sass';

export const StepHeader = ({ classes: receivedClasses = {} }) => {
    const { isSmall } = React.useContext(ApplicationContext);
    const { canGoBack } = React.useContext(StepsUtilsContext);

    return (
        <div className={cn(classes.root, isSmall && classes.small, receivedClasses.root)}>
            {canGoBack && <GoBackButton classes={{ root: classes.goBackButton }} />}
            {!isSmall && <StepsProgressBar />}
        </div>
    );
};
