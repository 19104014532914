import cn from 'classnames';
import * as React from 'react';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Button } from '../../../../designSystem/Button/Button';
import { Typography } from '../../../../designSystem/Typography/Typography';
import classes from '../IsolationEligibility/IsolationEligibility.module.sass';

export const IsolationInegibility = () => {
    const { language, isSmall } = React.useContext(ApplicationContext);

    return (
        <div className={cn(classes.root, isSmall && classes.small)}>
            <div className={classes.textsIllustrationContainer}>
                <div className={classes.texts}>
                    <Typography classes={{ root: classes.title }} variant="title">
                        {language.steps['commons.notEligibleGeneral.titleBulletPoints']}
                    </Typography>
                    <div className={classes.listSmallText}>
                        <ul className={classes.list}>
                            <li>{language.steps['contents.isolationInegibility.list.1']}</li>
                            <li>{language.steps['contents.isolationInegibility.list.2']}</li>
                            <li>{language.steps['contents.isolationInegibility.list.3']}</li>
                            <li>{language.steps['contents.isolationInegibility.list.4']}</li>
                        </ul>
                    </div>
                </div>
                <img
                    className={classes.illustration}
                    src="/assets/svg/lets-talk.svg"
                    alt={language.steps['contents.isolationEligibility.title']}
                />
            </div>
            <Button classes={{ root: classes.button }}>{language.steps['contents.isolationInegibility.button']}</Button>
        </div>
    );
};
