import { merge } from 'lodash';

import { pushDatas } from '../../../../helpers';
import { urlEndGeneral } from '../../../../utils/variables';
import { StepsGroups } from '../Groups/Groups.data';
import { routesTitles } from '../Steps.titles';
import {
    CURRENT_HOUSING_ENERGY_COMMON_STEP,
    ELECTRIC_EQUIPMENTS_COMMON_STEP,
    GAS_EQUIPMENTS_COMMON_STEP,
    HOUSING_MEMBERS_COUNT_COMMON_STEP,
    HOUSING_SURFACE_COMMON_STEP,
    HOUSING_TYPES_COMMON_STEP,
    IS_OLDER_THAN_TWO_YEARS_COMMON_STEP,
    LANDLORD_OR_TENANT_COMMON_STEP,
    NOT_ELIGIBLE_FOR_HEATING_OFFERS_COMMON_STEP,
    OIL_EQUIPMENTS_COMMON_STEP,
    PERSONAL_INFOS_COMMON_STEP,
    TAX_INCOME_COMMON_STEP,
    WORKS_POSTAL_CODE_COMMON_STEP,
    RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP,
    CONDENSING_GAZ_BOILER_DATE_COMMON_STEP,
} from './commonsSteps';

// 2.*.*

export const CHANGE_HEATING_STEPS = Object.freeze({
    [routesTitles.CHANGE_HEATING]: merge(
        {
            choices: {
                house: { nextStep: `2.1` },
                apartment: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        HOUSING_TYPES_COMMON_STEP
    ),
    2.1: merge(
        {
            choices: {
                landlord: { nextStep: `2.2` },
                tenant: { nextStep: routesTitles.GENERAL_INEGIBILITY },
            },
        },
        LANDLORD_OR_TENANT_COMMON_STEP
    ),
    2.2: {
        group: StepsGroups.project,
        titleTranslationKey: 'commons.whatInstall.title',
        type: 'choices',
        name: 'type_heating',
        choices: {
            heatPump: {
                icon: '/assets/svg/heat-pump.svg',
                translationKey: '2.1.choices.heatPump',
                nextStep: '2.4',
            },
            boiler: {
                icon: '/assets/svg/boiler.svg',
                translationKey: '2.1.choices.boiler',
                nextStep: '2.2.1',
            },
            woodStove: {
                icon: '/assets/svg/wood-stove.svg',
                translationKey: '2.1.choices.woodStove',
                nextStep: '2.4',
            },
            fireplaceInsert: {
                icon: '/assets/svg/fireplace-insert.svg',
                translationKey: '2.1.choices.fireplaceInsert',
                nextStep: '2.4',
            },
            electricHeater: {
                icon: '/assets/svg/electric-radiator.svg',
                translationKey: '2.1.choices.electricHeater',
                nextStep: '2.2.2',
            },
        },
    },
    '2.2.1': {
        group: StepsGroups.project,
        titleTranslationKey: '2.1.1.title',
        type: 'choices',
        choices: {
            gasCondensingBoiler: {
                icon: '/assets/svg/gaz-boiler.svg',
                translationKey: '2.1.1.choices.gasCondensingBoiler',
                nextStep: '2.2.3',
            },
            woodBoiler: {
                icon: '/assets/svg/timber-boiler.svg',
                translationKey: '2.1.1.choices.woodBoiler',
                nextStep: '2.4',
            },
            oilCondensingBoiler: {
                icon: '/assets/svg/fioul-boiler.svg',
                translationKey: '2.1.1.choices.oilCondensingBoiler',
                nextStep: '2.4',
            },
        },
    },
    '2.2.2': merge(
        {
            nextStep: '2.4',
        },
        RADIATORS_TO_INSTALL_OR_REPLACE_COUNT_COMMON_STEP
    ),
    '2.2.3': merge(
        {
            nextStep: (stepValues) =>
                new Date().getFullYear() - Number((stepValues[`2.2.3`] || {}).value) > 17
                    ? '2.4'
                    : routesTitles.GENERAL_INEGIBILITY,
        },
        CONDENSING_GAZ_BOILER_DATE_COMMON_STEP
    ),
    2.4: merge(
        {
            group: StepsGroups.project,
            nextStep: '2.5',
        },
        WORKS_POSTAL_CODE_COMMON_STEP
    ),
    2.5: merge(
        {
            group: StepsGroups.project,
            choices: {
                yes: {
                    nextStep: '2.6',
                },
                no: {
                    nextStep: routesTitles.GENERAL_INEGIBILITY,
                },
            },
        },
        IS_OLDER_THAN_TWO_YEARS_COMMON_STEP
    ),
    '2.5.1': merge(
        {
            group: StepsGroups.eligibility,
        },
        NOT_ELIGIBLE_FOR_HEATING_OFFERS_COMMON_STEP
    ),
    2.6: merge(
        {
            group: StepsGroups.project,
            choices: {
                oilHeating: {
                    nextStep: '2.7.1',
                },
                electricHeating: {
                    nextStep: '2.7.2',
                },
                coalHeating: {
                    nextStep: '2.8',
                },
                woodHeating: {
                    nextStep: '2.8',
                },
                heatPump: {
                    nextStep: '2.8',
                },
                gasHeating: {
                    nextStep: '2.7.3',
                },
            },
        },
        CURRENT_HOUSING_ENERGY_COMMON_STEP
    ),
    '2.7.1': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: '2.8',
                },
                otherThanCondensingHeating: {
                    nextStep: '2.8',
                },
                stove: {
                    nextStep: '2.8',
                },
            },
        },
        OIL_EQUIPMENTS_COMMON_STEP
    ),
    '2.7.2': merge(
        {
            group: StepsGroups.project,
            choices: {
                electricHeating: {
                    nextStep: '2.8',
                },
                heatedCeilingsOrFloors: {
                    nextStep: '2.8',
                },
                electricHeater: {
                    nextStep: '2.8',
                },
            },
        },
        ELECTRIC_EQUIPMENTS_COMMON_STEP
    ),
    '2.7.3': merge(
        {
            group: StepsGroups.project,
            choices: {
                condensingHeating: {
                    nextStep: '2.8',
                },
                condensingHeating2: {
                    nextStep: '2.8',
                },
                gasRadiator: {
                    nextStep: '2.8',
                },
                gasStove: {
                    nextStep: '2.8',
                },
            },
        },
        GAS_EQUIPMENTS_COMMON_STEP
    ),
    2.8: merge(
        {
            group: StepsGroups.infos,
            nextStep: '2.9',
        },
        HOUSING_SURFACE_COMMON_STEP
    ),
    2.9: merge(
        {
            group: StepsGroups.infos,
            nextStep: '2.10',
        },
        HOUSING_MEMBERS_COUNT_COMMON_STEP
    ),
    '2.10': merge(
        {
            group: StepsGroups.infos,
            choices: {
                lessThanX: {
                    nextStep: '2.11',
                },
                betweenXAndY: {
                    nextStep: '2.11',
                },
                betweenYAndZ: {
                    nextStep: '2.11',
                },
                moreThanZ: {
                    nextStep: '2.11',
                },
            },
        },
        TAX_INCOME_COMMON_STEP
    ),
    2.11: merge(
        {
            group: StepsGroups.infos,
            // nextStep: '2.12',
            runFunction: pushDatas,
            replace: urlEndGeneral,
        },
        PERSONAL_INFOS_COMMON_STEP
    ),
    // 2.12: merge(
    //     {
    //         group: StepsGroups.eligibility,
    //     },
    //     ELIGIBLE_FOR_BONUS_COMMON_STEP
    // ),
});
