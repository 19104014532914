import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Steps } from '../../routesComponents/Steps/Steps';

// Add routes if necessary.
export const Routes = React.memo(() => (
    <Switch>
        <Route exact path="/" component={Steps} />
        <Route exact path="/:stepIdOrEncodedPathsHistory" component={Steps} />
        <Route exact path="/:stepId/:encodedPathsHistory" component={Steps} />
        <Redirect from="*" to="/" />
    </Switch>
));
