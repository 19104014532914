import * as React from 'react';

import { ProgressBar } from '../../../designSystem/ProgressBar/ProgressBar';
import { StepsUtilsContext } from '../Steps.context';

export const StepsProgressBar = () => {
    const { progression } = React.useContext(StepsUtilsContext);

    return <ProgressBar value={progression} />;
};
