import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { FormField } from '../../../../commons/FormField/FormField';
import { TextField } from '../../../../designSystem/TextField/TextField';

export const SimpleField = ({ labelTranslationKey, ...other }) => {
    const { language } = React.useContext(ApplicationContext);
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <FormField error={errors.value} {...(labelTranslationKey && { label: language.steps[labelTranslationKey] })}>
            <TextField {...other} {...register('value')} />
        </FormField>
    );
};
