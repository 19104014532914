import { commonsLanguage } from './commons.language';
import { formLanguage } from './form.language';
import { groupLanguage } from './groups.language';
import { sidePanelLanguage } from './sidePanel.language';
import { stepsLanguage } from './steps.language';
import { titlesLanguage } from './titles.language';

export const getLanguage = (value = 'fr') => ({
    commons: commonsLanguage[value],
    groups: groupLanguage[value],
    sidePanel: sidePanelLanguage[value],
    steps: stepsLanguage[value],
    form: formLanguage[value],
    titles: titlesLanguage[value],
});

export default {
    fr: getLanguage(),
    us: getLanguage('us'),
};
