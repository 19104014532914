// Same as variables.sass
export const primary = '#1B79B4';
export const secondary = '#88D0A3';
export const textColor = '#3c3c3b';
export const whiteColor = 'white';

export const spacing = 8;
export const urlEndGeneral = 'https://www.flammebleue-environnement.fr/bienvenue-chez-flammebleue-environnement/';
export const names = [
    'housing_type',
    'isolation_needs_type',
    'attics_type',
    'garage_type',
    'nbr_persons',
    'type_energie',
    'ventilation_mecanic_controlled',
    'accessible_lost_attics',
    'accessible_crawlspace',
    'vs_hauteur',
    'surface_habitable_chauffe',
    'levels_heat_count',
    'tax_income',
    'condensing_gaz_boiler',
    'crawlspace_trap_contents',
    'condensing_gaz_boiler_year',
    'refresh_energy_type',
    'surface_mur',
    'basement_isolation_type',
    'type_heating',
    'height_under_ceiling',
    'work_type',
    'acceptConditions',
    'value',
    'civilite',
    'first_name',
    'last_name',
    'phones',
    'emails',
    'city',
    'campaign',
    'tag',
    'utmSource',
    'utmParameters',
    'full_name',
    'landlord_or_tenant',
    'zipCode',
    'profite_iso',
];
