import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as CheckIcon } from '../../../../../assets/svg/check.svg';
import { ReactComponent as WaveIcon } from '../../../../../assets/svg/wave.svg';
import { ApplicationContext } from '../../../../commons/Application/Application.context';
import { Typography } from '../../../../designSystem/Typography/Typography';
import { StepsUtilsContext } from '../../Steps.context';
import { StepsGroups } from '../Groups.data';
import classes from './GroupItem.module.sass';
import { getCircleSpringProps } from './GroupItem.springProps';

export const GroupItem = ({ groupId, isSmall, classes: receivedClasses = {} }) => {
    const { language } = React.useContext(ApplicationContext);
    const { currentStep } = React.useContext(StepsUtilsContext);

    const currentGroupId = currentStep.group;

    const isCurrent = React.useMemo(() => currentGroupId === groupId, [currentGroupId, groupId]);

    const isPast = React.useMemo(() => {
        const groupsKeys = Object.keys(StepsGroups);
        const currentGroupIndex = groupsKeys.findIndex((entry) => entry === currentGroupId);
        return currentGroupIndex > groupsKeys.findIndex((entry) => entry === groupId);
    }, [groupId, currentGroupId]);

    const circleSpringProps = useSpring(getCircleSpringProps(isPast, isCurrent));

    const Icon = React.useMemo(() => {
        if (isCurrent) {
            return WaveIcon;
        }
        if (isPast) {
            return CheckIcon;
        }
        return null;
    }, [isCurrent, isPast]);

    return (
        <div
            className={cn(
                classes.root,
                isPast && classes.past,
                isCurrent && classes.current,
                isSmall && classes.small,
                receivedClasses.root
            )}
        >
            <animated.div className={classes.circle} style={circleSpringProps}>
                {Boolean(isPast || isCurrent) && <Icon className={classes.icon} />}
            </animated.div>
            {!isSmall && <Typography classes={{ root: classes.typography }}>{language.groups[groupId]}</Typography>}
        </div>
    );
};
