export const FR = {
    '1.3.2.title': 'Quelle est la surface totale du garage à isoler ?',

    '1.3.4.title': 'Quelle est la surface totale du vide sanitaire à isoler ?',

    '1.8.title': "Félicitations ! Vous êtes éligible à l'offre*  :",

    '2.1.choices.heatPump': 'Une pompe à chaleur',
    '2.1.choices.none': 'Aucune',
    '2.1.choices.boiler': 'Une chaudière',
    '2.1.choices.woodStove': 'Une poêle à bois',
    '2.1.choices.fireplaceInsert': 'Un insert cheminée',
    '2.1.choices.electricHeater': 'Des radiateurs électriques',

    'commons.condensingGazBoiler.title': "S'agit-il d'une chaudière à condensation ?",
    'commons.condensingGazBoilerDate.title': 'De quelle année date votre chaudière à condensation ?',

    '2.1.1.title': 'Quel type de chaudière souhaitez-vous installer ?',
    '2.1.1.choices.gasCondensingBoiler': 'Chaudière gaz à condensation',
    '2.1.1.choices.woodBoiler': 'Chaudière à bois',
    '2.1.1.choices.oilCondensingBoiler': 'Chaudière fioul à condensation',

    '2.1.2.title': 'Combien de radiateurs souhaitez-vous installer ou remplacer ?',

    '3.10.title': 'Effectuez-vous ces travaux dans votre résidence principale ?',
    '3.10.choices.yes': 'Oui',
    '3.10.choices.no': 'Non',

    '4.3.title': 'De quand date la construction de votre logement ?',
    '4.3.choices.lessThanTwoYears': 'Moins de 1 an',
    '4.3.choices.betweenTwoAndFifteenYears': 'Entre 2 et 15 ans',
    '4.3.choices.moreThanFifteenYears': 'Plus de 15 ans',

    '4.7.title': 'Quel est le montant de votre facture de chauffage ?',

    '4.10.title': 'Quel est le budget estimé de vos travaux ?',

    '4.12.title': 'Avez-vous perçu une aide pour des travaux de rénovation énergétique ces 5 dernières années ?',
    '4.12.subTitle': 'Anah, Crédit d’impôt pour la transition énergétique, Eco-prêt à taux 0...',
    '4.12.choices.yes': 'Oui',
    '4.12.choices.no': 'Non',

    '4.12.1.title': 'Quelle(s) aide(s) avez-vous perçu ces 5 dernières années ?',
    '4.12.1.subTitle': 'Vous pouvez sélectionner plusieurs aides',
    '4.12.1.choices.transitionTaxCredit': 'Crédit d’impôt pour la transition',
    '4.12.1.choices.noRateEcoLoan': 'Eco-prêt à taux zéro',
    '4.12.1.choices.anah': 'Anah (Habiter Mieux)',
    '4.12.1.choices.others': 'Autre(s) aide(s)',

    '4.13.title': 'Avez-vous perçu une aide pour des travaux de rénovation énergétique ces 5 dernières années ?',
    '4.13.subTitle': 'Anah, Crédit d’impôt pour la transition énergétique, Eco-prêt à taux 0...',
    '4.13.choices.ownerOccupied': 'Propriétaire occupant',
    '4.14.choices.secondHomeOwner': 'Propriétaire d’une résidence secondaire',
    '4.14.choices.landlordOwner': 'Propriétaire bailleur',
    '4.13.choices.tenant': 'Locataire',

    '4.15.title': 'Dans quelle tranche se situent les revenus fiscaux de votre foyer ?',
    '4.15.subTitle': 'Il s’agit du revenu de l’ensemble des membres du foyer fiscal.',
    '4.15.choices.lessThanX': 'Inférieur à X€',
    '4.15.choices.betweenXAndY': 'Entre X€ et Y€',
    '4.15.choices.betweenYAndZ': 'Entre Y€ et Z€',
    '4.15.choices.moreThanZ': 'Supérieur à Z€',

    '4.17.title': 'Estimation des aides pour votre projet*',

    '5.4.title': 'Quelle est la surface à isoler ?',

    'commons.renovateNeeds.title': 'Quels sont vos besoins de rénovation ?',
    'commons.renovateNeeds.choices.ECS': 'Eau chaude sanitaire',
    'commons.garageType.title': 'Garage interne ou externe à ma maison ?',
    'commons.garageType.choices.intern': 'Garage interne',
    'commons.garageType.choices.extern': 'Garage externe',
    'commons.atticsIsolationSurface.title': 'Quelle est la surface de combles à isoler ?',

    'commons.atticsIsolationTypes.title': 'L’isolation des combles concerne :',
    'commons.atticsIsolationTypes.choices.lostAttics': 'Des combles perdus',
    'commons.atticsIsolationTypes.choices.convertedAttics': 'Des combles aménagés',
    'commons.atticsIsolationTypes.choices.multipleAttics': 'Les deux',

    'commons.accessibleLostAttics.title': 'Les combles perdus sont-ils accessibles ?',
    'commons.accessibleCrawlspaceAttics.title': 'Le vide sanitaire est-il accessible ?',
    'commons.crawlspaceHeight.title': 'Quelle est la hauteur du vide sanitaire ?',
    'commons.crawlspaceTrapContents.title': 'Le vide sanitaire contient-il une trappe ?',

    'commons.basementIsolationSurface.title': 'Quelle est la surface totale du sous-sol à isoler ?',

    'commons.basementIsolationTypes.title': 'L’isolation du sous-sol concerne :',
    'commons.basementIsolationTypes.choices.cave': 'Une cave',
    'commons.basementIsolationTypes.choices.platform': 'Un terre-plein',
    'commons.basementIsolationTypes.choices.crawlspace': 'Un vide sanitaire',
    'commons.basementIsolationTypes.choices.garage': 'Un garage',

    'commons.currentHousingEnergy.title': 'Quelle est l’énergie de chauffage actuelle de votre logement ?',
    'commons.currentHousingEnergyHotWater.title':
        "Quel est votre mode de production actuel de l'eau chaude sanitaire ?",
    'commons.currentHousingEnergyRefresh.title': 'Quelle est l’énergie de rafraichissement de votre logement ?',

    'commons.currentHousingEnergyGlobale.subTitle':
        'Le programme de Rénovation Globale <br/><i>(changement du système de chauffage + isolation)</i><br/> concerne uniquement les propriétaires chauffés au <b>Gaz ou au Fioul ! </b>',
    'commons.heightUnderCeiling.title': 'Quelle est la hauteur sous plafond ?',

    'commons.currentHousingEnergy.subTitle':
        'Si vous avez plusieurs énergies de chauffage, indiquez-nous la principale',
    'commons.currentHousingEnergy.choices.oilHeating': 'Chauffage au Fioul',
    'commons.currentHousingEnergy.choices.electricHeating': 'Chauffage électrique',
    'commons.currentHousingEnergy.choices.coalHeating': 'Chauffage au charbon',
    'commons.currentHousingEnergy.choices.woodHeating': 'Chauffage au bois',
    'commons.currentHousingEnergy.choices.heatPump': 'Pompe à chaleur',
    'commons.currentHousingEnergy.choices.gasHeating': 'Chauffage au gaz',

    'commons.isolationOneEuroAlreadyDone.title': "Avez-vous déjà bénéficié d'une isolation à 1€ ?",
    'commons.isolationOneEuroAlreadyDone.subTitle':
        'Si vous avez déjà profité d’une isolation à 1€, vous n’êtes pas éligible à notre programme de Rénovation Globale',

    'commons.currentProjectStep.title': 'Où en êtes-vous dans votre projet ?',
    'commons.currentProjectStep.choices.thinking': 'Je réflechis à mes travaux',
    'commons.currentProjectStep.choices.searchingForCraftsman': 'Je recherche un artisan RGE',
    'commons.currentProjectStep.choices.aboutToSignQuotation': 'Je vais signer mon devis',
    'commons.currentProjectStep.choices.alreadySignedQuotation': 'J’ai déjà signé mon devis',

    'commons.electricEquipments.title': "Quel type d'équipement électrique est installé ?",
    'commons.electricEquipments.choices.electricHeating': 'Chauffage électrique',
    'commons.electricEquipments.choices.heatedCeilingsOrFloors': 'Plafonds ou planchers chauffants',
    'commons.electricEquipments.choices.electricHeater': 'Radiateurs électriques',

    'commons.eligibleForBonus.title': 'Bonne nouvelle ! Vous êtes éligible à la Prime Flammebleue',
    'commons.eligibilityAids.title': 'Bonne nouvelle ! Vous êtes éligible à la ',
    'commons.eligibilityAids.titleBonus': 'Prime Flammebleue',
    'contents.eligibilityAids.title.1': "Vous pourrez percevoir jusqu'à ",
    'contents.eligibilityAids.title.2': " € d'aides pour le bouquet de travaux sélectionné",

    'commons.MPREligibility.list.1':
        "Aide du dispositif des Certificats d'Economies d'Energie (CEE) mis en place par l'Etat + Ma prime Renov",
    'commons.MPREligibility.list.2': 'La demande de prime doit être effectuée avant la signature du devis',

    'commons.gasEquipments.title': "Quel type d'équipement au gaz est installé ?",
    'commons.gasEquipments.choices.condensingHeating': 'Chauffage à condensation',
    'commons.gasEquipments.choices.condensingHeating2': 'Chaudière autre que condensation',
    'commons.gasEquipments.choices.gasRadiator': 'Radiateur au gaz',
    'commons.gasEquipments.choices.gasStove': 'Poêle au gaz',

    'commons.heatPumpTypes.title': 'Quel type de pompe à chaleur air/eau souhaitez-vous installer ?\n',
    'commons.heatPumpTypes.choices.lowTemperature': 'Basse température',
    'commons.heatPumpTypes.choices.mediumTemperature': 'Moyenne température',
    'commons.heatPumpTypes.choices.highTemperature': 'Haute température',
    'commons.heatPumpTypes.choices.notKnown': 'Je ne sais pas',

    'commons.housingLocationPostalCode.title': 'Où se situe le logement concerné par les travaux ?',

    'commons.housingMembersCount.title': 'Combien de personnes composent votre foyer, vous compris ?',

    'commons.housingSurface.title': 'Quelle est la surface habitable de votre logement ?',
    'commons.howManylevelsHeat.title': 'Combien de niveaux chauffez-vous ?',
    'commons.howManylevelsHeat.subTitle': 'Hors combles et sous-sols',

    'commons.housingTypes.title': 'Les travaux concernent-ils un appartement ou une maison ?',
    'commons.housingTypes.choices.house': 'Maison',
    'commons.housingTypes.choices.apartment': 'Appartement',

    'commons.isOlderThanTwoYears.title': 'Votre logement a-t-il plus de 1 an ?',
    'commons.isOlderThanTwoYears.choices.yes': 'Oui',
    'commons.isOlderThanTwoYears.choices.no': 'Non',

    'contents.authentication.commons.fields.email.label': 'Email',

    'contents.authentication.forgotPassword.title': 'Vous avez oublié votre mot de passe ?',
    'contents.authentication.forgotPassword.subTitle':
        'Pas de soucis ! Nous allons vous envoyer un email de réinitialisation.',
    'contents.authentication.forgotPassword.action.login': "J'ai retrouvé mon mot de passe",

    'contents.authentication.login.title': 'Connectez-vous à votre compte',
    'contents.authentication.login.action.register': "S'inscire",
    'contents.authentication.login.fields.password.label': 'Mot de passe',
    'contents.authentication.login.fields.password.forgotPassword': 'Mot de passe oublié ?',

    'contents.authentication.register.title': 'Quelles sont vos informations de connexion ?',
    'contents.authentication.register.subTitle':
        'Pour suivre votre projet et compléter votre dossier, créez votre compte dès maintenant.',
    'contents.authentication.register.action.alreadyHaveAccount': "J'ai déjà un compte",
    'contents.authentication.register.fields.acceptConditions.label':
        'J’accepte que mes données soient collectées pour bénéficier des services Flamme Bleue Environnement.',
    'contents.authentication.register.smallText':
        'En cliquant sur « Valider », je reconnais avoir pris connaissance des Conditions Générales Flamme Bleue Environnement, de la Politique de Protection des Données et je les accepte',

    // Might be an easier way to do this. Ex: with react-i18next, you can do 'Mais <0>FlammeBleue</0> a pensé à <1>vous</1> !'.
    'contents.installHeatingFromPartners.title.part.0': 'Mais ',
    'contents.installHeatingFromPartners.title.part.1': 'FlammeBleue',
    'contents.installHeatingFromPartners.title.part.2': ' a pensé à ',
    'contents.installHeatingFromPartners.title.part.3': 'vous',
    'contents.installHeatingFromPartners.title.part.4': ' !',
    'contents.installHeatingFromPartners.subTitle':
        'Faites installer votre chauffage par l’un de nos partenaires installateurs.',
    'contents.installHeatingFromPartners.smallText':
        'Recevez des devis gratuits et sans engagement, et choisissez le meilleur partenaire pour vos travaux.',
    'contents.installHeatingFromPartners.button': 'Recevoir des devis',

    'contents.isolationEligibility.title': 'Isolation des combles à',
    'contents.isolationEligibility.list.1': 'Artisan local certifié RGE',
    'contents.isolationEligibility.list.2': 'Visite de contrôle gratuite avant chantier',
    'contents.isolationEligibility.list.3': 'Travaux garantis 10 ans',
    'contents.isolationInegibility.list.1': 'Vous n’êtes pas propriétaire de votre logement',
    'contents.isolationInegibility.list.2': 'Vous habitez en appartement',
    'contents.isolationInegibility.list.3': 'Votre logement a été construit il y a moins d’un an',
    'contents.isolationInegibility.list.4': 'Votre chaudière à condensation a moins de 18 ans',
    'contents.isolationEligibility.smallText':
        '* Votre éligibilité indiquée sur cette page tient compte des seules informations que vous avez renseignées lors de votre simulation. Elle reste soumise à l’étude de votre dossier et à la faisabilité technique des travaux vérifiée par un de nos Partenaires Flamme Bleue Environnement.',
    'contents.isolationEligibility.button':
        'Un conseiller FLAMMEBLEUE vous contactera rapidement au XX XX XX XX XX pour concrétiser votre projet',
    'contents.isolationInegibility.button': 'Contactez un conseiller commercial au 0 805 036 745',
    'commons.isolationSelection.title': 'Que souhaitez-vous isoler ?',
    'commons.isolationSelection.choices.attics': 'Des combles',
    'commons.isolationSelection.choices.garage': 'Un garage',
    'commons.isolationSelection.choices.brickVault': 'Voûte en brique',
    'commons.isolationSelection.choices.basement': 'Sous-sol',
    'commons.isolationSelection.choices.crawlspace': 'Un vide sanitaire',
    'commons.isolationSelection.choices.interior': "Isolation des murs par l'intérieur",

    'commons.landlordOrTenant.title': 'Dans ce logement, vous êtes :',
    'commons.landlordOrTenant.choices.landlord': 'Propriétaire',
    'commons.landlordOrTenant.choices.tenant': 'Locataire',

    'commons.notEligibleGeneral.title': 'Malheureusement...',
    'commons.notEligibleGeneral.subTitle': 'Vous n’êtes pas éligible à la Prime Flammebleue',
    'commons.notEligibleGeneral.titleBulletPoints':
        'Vous n’êtes pas éligible aux aides de l’état pour l’un des critère suivant :',
    'commons.notEligibleForHeatingOffers.title':
        'Vous n’êtes pas éligible à l’installation de nos offres dédiées au Chauffage.',
    'commons.notEligibleForHeatingOffers.subTitle':
        'Ces offres ne sont pas éligibles aux logements récents (construits depuis moins de 1 an).',

    'commons.oilEquipments.title': "Quel type d'équipement au fioul est installé ?",
    'commons.oilEquipments.choices.condensingHeating': 'Chauffage à condensation',
    'commons.oilEquipments.choices.otherThanCondensingHeating': 'Chauffage autre qu’à condensation',
    'commons.oilEquipments.choices.stove': 'Poêle',

    'commons.personalInfos.title': 'Vos coordonnées',
    'commons.personalInfos.subTitle': 'Vous acceptez d’être rappelé par l’un de nos conseillers',

    'commons.principalResidencePostalCode.title': 'Quel est le code postal de votre résidence principale ?',

    'commons.radiatorsToInstallOrReplaceCount.title': 'Combien de radiateurs souhaitez-vous installer ou remplacer ?',

    'commons.whatInstall.title': 'Que souhaitez-vous installer ?',

    'commons.roofIsolationSurface.title': 'Quelle est la surface de toiture à isoler ?',

    'commons.solarPanelsSurface.title': 'Quelle est la surface de panneaux solaires à installer ?',

    'commons.ventilationTypes.title': 'Votre logement possède-t-il une ventilation mécanique contrôlée ? (VMC)',

    'commons.taxIncomeNumber.title': 'Quel est votre revenu fiscal ?',
    'commons.taxIncome.title': 'Dans quelle tranche se situent les revenus fiscaux de votre foyer ?',
    'commons.taxIncome.subTitle': 'Il s’agit du revenu de l’ensemble des membres du foyer fiscal.',
    'commons.taxIncome.choices.lessThanX': 'Inférieur à 30 225 €',
    'commons.taxIncome.choices.betweenXAndY': 'Entre 30 225 € à 39 792 €',
    'commons.taxIncome.choices.betweenYAndZ': 'Entre 39 792 € et 56 130 €',
    'commons.taxIncome.choices.moreThanZ': 'Supérieur à 56 130 €',

    'commons.wallsIsolationSurface.title': 'Quelle est la surface de murs à isoler ?',

    'commons.wallsIsolationTypes.title': 'Quel type d’isolation des murs souhaitez-vous faire ?',
    'commons.wallsIsolationTypes.choices.interior': 'Isolations intérieur',
    'commons.wallsIsolationTypes.choices.exterior': 'Isolations extérieur',
    'commons.wallsIsolationTypes.choices.notKnown': 'Je ne sais pas',

    'commons.windowsIsolationCount.title': 'Combien de fenêtres sont concernées par votre projet ?',

    'commons.works.title': 'Quels travaux souhaitez-vous réaliser dans votre logement ?',
    'commons.works.categories.isolationVentilation.title': 'Isolation / Ventilation',
    'commons.works.categories.isolationVentilation.choices.walls': 'Isolations des murs',
    'commons.works.categories.isolationVentilation.choices.windows': 'Fenêtres',
    'commons.works.categories.isolationVentilation.choices.grilles': 'Grilles hautes et basses',
    'commons.works.categories.isolationVentilation.choices.VMC': 'Ventilation mécanique contrôlée (VMC)',
    'commons.works.categories.isolationVentilation.choices.flatRoof': 'Isolation d’une toiture-térasse',
    'commons.works.categories.isolationVentilation.choices.attics': 'Isolations des combles',
    'commons.works.categories.isolationVentilation.choices.floors': 'Isolations des sols',
    'commons.works.categories.heatPump.title': 'Pompe à chaleur',
    'commons.works.categories.heatPump.choices.airWaterHeatPump': 'Pompe à Chaleur Air/Eau',
    'commons.works.categories.heatPump.choices.airConditionerIntern': 'Climatiseur mural',
    'commons.works.categories.heatPump.choices.airConditionerPortable': 'Climatiseur portable',
    'commons.works.categories.heatPump.choices.geothermalHeatPump': 'Pompe à Chaleur Géothermique',
    'commons.works.categories.heatPump.choices.airAirHeatPump': 'Pompe à Chaleur Air/Air',
    'commons.works.categories.heatPump.choices.hybridHeatPump': 'Pompe à Chaleur Hybride',
    'commons.works.categories.traditionalHeating.title': 'Chauffage traditionnel',
    'commons.works.categories.isolationVentilation.choices.oilCondensingBoiler': 'Chaudière fioul à condensation',
    'commons.works.categories.isolationVentilation.choices.gasCondensingBoiler': 'Chaudière gaz à condensation',
    'commons.works.categories.isolationVentilation.choices.inertiaRadiator': 'Radiateur à inertie',
    'commons.works.categories.waterHeater.title': 'Chauffe-eau',
    'commons.works.categories.waterHeater.choices.thermodynamicWaterHeater': 'Chauffe-eau Thermodynamique',
    'commons.works.categories.waterHeater.choices.individualSolarWaterHeater': 'Chauffe-eau Solaire Individuel',
    'commons.works.categories.waterHeater.choices.electricWaterHeater': 'Chauffe-eau électrique',
    'commons.works.categories.woodHeating.title': 'Chauffage à bois',
    'commons.works.categories.woodHeating.choices.fireplaceInsert': 'Insert cheminée',
    'commons.works.categories.woodHeating.choices.woodStove': 'Poêle à bois (granulés ou bûches)',
    'commons.works.categories.woodHeating.choices.woodBoiler': 'Chaudière à bois',
    'commons.works.categories.solar.title': 'Solaire',
    'commons.works.categories.solar.choices.combinedSolarSystem': 'Système Solaire Combiné',
    'commons.works.categories.solar.choices.photovoltaicSolarPanel': 'Panneaux Solaires Photovoltaïques',
    'commons.works.categories.solar.choices.aerovoltaticPanels': 'Panneaux Aérovoltaïques',

    'commons.worksPostalCode.title': 'Quel est le code postal où seront réalisés les travaux ?',

    // Format is: fields.TYPE.FIELD_NAME.(label|option.*)
    'fields.heatingBill.unit.option.year': 'an',
    'fields.heatingBill.unit.option.month': 'mois',
    'fields.heatingBill.every': 'tous les',

    'fields.meterMeasure.value.label': 'Mesure en mètres',
    'fields.personalInfos.gender.option.male': 'Monsieur',
    'fields.personalInfos.gender.option.female': 'Madame',
    'fields.personalInfos.firstName.label': 'Prénom',
    'fields.personalInfos.lastName.label': 'Nom',
    'fields.personalInfos.phone.label': 'Téléphone',
    'fields.postalCode.value.label': 'Code postal',
    'fields.city.value.label': 'Ville',
    'fields.surfaceHabitable.value.label': 'Surface habitable en m²',
    'fields.surfacePose.value.label': 'Surface de pose en m²',
    'fields.surface.value.label': 'Surface à isoler en m²',
    'fields.heightUnderCeiling.value.label': 'Mesure en mètre',
    'fields.taxIncome.value.label': 'Revenu en euros',

    'home.title': 'Quels sont vos besoins ?',
    'home.subTitle': 'Je souhaite :',
    'home.choices.isolateHouse': 'Isoler ma maison',
    'home.choices.changeHeating': 'Changer mon chauffage',
    'home.choices.applyForBonus': 'Demander ma prime',
    'home.choices.calculateAids': 'Calculer mes aides',

    continue: 'Continuer',
    validate: 'Valider',
};

export const EN = FR;

export const stepsLanguage = {
    fr: FR,
    us: EN,
};
